import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { Observable, map, of } from 'rxjs';
import {
  EntityCatalog,
  FieldSet,
  Layout,
  MetadataCatalog,
  Octopus,
} from '@maximizer/core/shared/domain';

@Injectable()
export class MetadataService {
  catalog: MetadataCatalog = {};

  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  getEntity(
    entity: Octopus.EntityCode,
    ignoreCache = false,
  ): Observable<EntityCatalog> {
    if (
      !ignoreCache &&
      this.catalog[entity] &&
      Object.keys(this.catalog[entity].fields).length
    ) {
      return of(this.catalog[entity]);
    }

    return this.http
      .post<Octopus.CatalogResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        this.getCatalogRequest(entity),
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.MetadataMapper(entity);
            const catalog = mapper.from(result);
            this.catalog[entity] = catalog;
            return catalog;
          }
          return {
            entity,
            fields: {},
            folders: { all: {}, tree: [] },
            layouts: [],
          } as EntityCatalog;
        }),
      );
  }

  getFields(entity: Octopus.EntityCode): Observable<FieldSet> {
    if (this.catalog[entity]?.fields) {
      return of(this.catalog[entity].fields);
    }

    return this.http
      .post<Octopus.MetadataResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        this.getMetadataRequest(entity),
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.MetadataMapper(entity);
            const fields = mapper.mapFields(result.$Metadata.Data);

            if (!this.catalog[entity]) {
              this.catalog[entity] = {
                entity,
                layouts: [],
                fields,
                folders: { all: {}, tree: [] },
              };
            } else {
              this.catalog[entity].fields = fields;
            }

            return fields;
          }
          return {};
        }),
      );
  }

  getLayouts(entity: Octopus.EntityCode): Observable<Layout[]> {
    if (this.catalog[entity]?.layouts?.length) {
      return of(this.catalog[entity].layouts);
    }

    return this.http
      .post<Octopus.KeyFieldDefinitionResponse & Octopus.KeyFieldListResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        {
          ...this.getFieldListRequest(),
          ...this.getKeyFieldDefinitionRequest(entity),
        },
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.MetadataMapper(entity);
            const layouts = mapper.mapLayouts(
              result.KeyFieldDefinition.Data,
              result.KeyFieldList.Data,
            );

            if (!this.catalog[entity]) {
              this.catalog[entity] = {
                entity,
                layouts,
                fields: {},
                folders: { all: {}, tree: [] },
              };
            } else {
              this.catalog[entity].layouts = layouts;
            }

            return layouts;
          }
          return [];
        }),
      );
  }

  deleteKeyFieldList(key: string): Observable<boolean> {
    const request: Octopus.KeyFieldListWriteRequest = {
      KeyFieldList: {
        Data: {
          Key: key,
        },
      },
    };
    return this.http
      .post<Octopus.KeyFieldListWriteResponse>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }

  deleteKeyFieldDefinition(key: string): Observable<boolean> {
    const request: Octopus.KeyFieldDefinitionWriteRequest = {
      KeyFieldDefinition: {
        Data: {
          Key: key,
        },
      },
    };
    return this.http
      .post<Octopus.KeyFieldListWriteResponse>(
        `${this.context.api}${Octopus.Action.DELETE}`,
        request,
      )
      .pipe(
        map((result) => {
          return result.Code === Octopus.ResponseStatusCode.Successful;
        }),
      );
  }

  createOrUpdateKeyFieldList(
    keyFieldList: Octopus.KeyFieldList,
  ): Observable<Octopus.KeyFieldList | null> {
    const request: Octopus.KeyFieldListWriteRequest = {
      KeyFieldList: {
        Data: keyFieldList,
      },
    };

    return this.http
      .post<Octopus.KeyFieldListWriteResponse>(
        `${this.context.api}${keyFieldList.Key ? Octopus.Action.UPDATE : Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.KeyFieldList.Data;
          }
          return null;
        }),
      );
  }

  creatOrUpdateKeyFieldDefinition(
    keyFieldDefinition: Octopus.KeyFieldDefinition,
  ): Observable<Octopus.KeyFieldDefinition | null> {
    const request: Octopus.KeyFieldDefinitionWriteRequest = {
      KeyFieldDefinition: {
        Data: keyFieldDefinition,
      },
    };

    return this.http
      .post<Octopus.KeyFieldDefinitionWriteResponse>(
        `${this.context.api}${keyFieldDefinition.Key ? Octopus.Action.UPDATE : Octopus.Action.CREATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            return result.KeyFieldDefinition.Data;
          }
          return null;
        }),
      );
  }

  existsKeyFieldDefinition(
    entity: Octopus.EntityCode,
    name: string,
    target: string,
  ): Observable<boolean> {
    const request: Octopus.KeyFieldDefinitionRequest = {
      KeyFieldDefinition: {
        Criteria: {
          SearchQuery: {
            EntityType: {
              $EQ: Octopus.EntityType[entity],
            },
            Name: {
              $EQ: name,
            },
            Target: {
              $EQ: target,
            },
          },
        },
        Scope: {
          Fields: {
            Key: 1,
            ParentKey: 1,
          },
        },
      },
      Compatibility: {
        KeyFieldDefinitionObject: '1.0',
      },
    };
    return this.http
      .post<Octopus.KeyFieldDefinitionResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
      )
      .pipe(
        map((result) => {
          return (
            result.Code === Octopus.ResponseStatusCode.Successful &&
            result.KeyFieldDefinition.Data.length > 0
          );
        }),
      );
  }

  private getMetadataRequest(
    entity: Octopus.EntityCode,
  ): Octopus.MetadataRequest {
    return {
      $Metadata: {
        Criteria: {
          SearchQuery: {
            Key: {
              $TREE: `/${entity}`,
            },
          },
        },
        Scope: {
          Fields: {
            Key: {
              Value: 1,
            },
            Name: 1,
            Description: 1,
            Alias: 1,
            AppliesTo: 1,
            Type: 1,
            Assignable: 1,
            Inactive: 1,
            Sortable: 1,
            Queryable: 1,
            HasOption: 1,
            ReadOnly: 1,
            Nullable: 1,
            Attributes: 1,
            Folder: 1,
            Path: 1,
            Mandatory: 1,
            Formula: 1,
            SortValue: 1,
          },
        },
      },
      Compatibility: {
        Schema: '2.0',
      },
    };
  }

  private getSchemaFolderRequest(
    entity: Octopus.EntityCode,
  ): Octopus.SchemaFolderRequest {
    return {
      SchemaFolder: {
        Scope: {
          Fields: {
            Key: 1,
            Name: 1,
            Path: 1,
            SortValue: 1,
            ParentFolderKey: 1,
            AppliesTo: 1,
          },
        },
        Criteria: {
          SearchQuery: {
            AppliesTo: {
              $EQ: [entity],
            },
          },
        },
      },
    };
  }

  private getKeyFieldDefinitionRequest(
    entity: Octopus.EntityCode,
  ): Octopus.KeyFieldDefinitionRequest {
    return {
      KeyFieldDefinition: {
        Criteria: {
          SearchQuery: {
            EntityType: {
              $EQ: Octopus.EntityType[entity],
            },
          },
        },
        Scope: {
          Fields: {
            Key: {
              Value: 1,
              Id: 1,
            },
            ParentKey: 1,
            EntityType: 1,
            Rule: 1,
            Name: 1,
            Target: 1,
            Layout: [
              {
                Name: 1,
                ColumnNo: 1,
                Element: [
                  {
                    Id: 1,
                  },
                ],
              },
            ],
          },
        },
        Options: {
          IncludeSeparator: true,
        },
      },
      Compatibility: {
        KeyFieldDefinitionObject: '1.0',
      },
    };
  }

  private getFieldListRequest(): Octopus.KeyFieldListRequest {
    return {
      KeyFieldList: {
        Scope: {
          Fields: {
            Key: 1,
            Name: 1,
            Description: 1,
            SecAccess: {
              Read: [
                {
                  Key: { Value: 1 },
                },
              ],
              Write: [
                {
                  Key: { Value: 1 },
                },
              ],
            },
            SecStatus: {
              CanCreate: 1,
              CanRead: 1,
              CanUpdate: 1,
              CanDelete: 1,
            },
          },
        },
      },
    };
  }

  private getCatalogRequest(
    entity: Octopus.EntityCode,
  ): Octopus.CatalogRequest {
    switch (entity) {
      case 'AbEntry':
      case 'Case':
      case 'Campaign':
      case 'Opportunity':
        return {
          ...this.getMetadataRequest(entity),
          ...this.getSchemaFolderRequest(entity),
          ...this.getFieldListRequest(),
          ...this.getKeyFieldDefinitionRequest(entity),
        };
      case 'Address':
        return { ...this.getMetadataRequest(entity) };
      default:
        return {
          ...this.getMetadataRequest(entity),
          ...this.getSchemaFolderRequest(entity),
        };
    }
  }
}
