<div class="flex p-4 bg-max-white justify-between">
  <div>
    <span class="svg-icons icon-case icon-24"></span>
  </div>
  <div class="max-w-[calc(100%-4.375rem)] flex-grow px-2 flex flex-col gap-y-1">
    <p class="text-body line-clamp-1">{{ case.subject }}</p>
    <p class="caption line-clamp-1">{{ case.abEntry.companyName }}</p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.case.status' | translate }}: {{ case.status }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.case.title' | translate }}: {{ case.caseNumber }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.case.contact' | translate }}: {{ case.contact?.firstName }}
      {{ case.contact?.lastName }}
    </p>
    @if (case.owner) {
      <div>
        <p class="text-body py-1">{{ 'outlook.case.owner' | translate }}:</p>
        <div class="flex items-center">
          <span class="avatar avatar-xs">{{
            case.owner.display | nameInitials
          }}</span>
          <p class="text-body pl-1">{{ case.owner.display }}</p>
        </div>
      </div>
    }
  </div>
  <span
    kendoTooltip
    class="h-fit"
    [callout]="false"
    [tooltipTemplate]="tooltipTemplate"
    [title]="
      userRights?.customerService?.update
        ? userRights?.documents?.insert
          ? ('outlook.main.related.saveEmail'
            | translate: { entry: 'outlook.entries.case' | translate })
          : ('outlook.rights.defaultMessage'
            | translate
              : {
                  right: 'outlook.rights.create' | translate,
                  entry: 'outlook.entries.emailLogs' | translate | lowercase,
                })
        : ('outlook.rights.defaultMessage'
          | translate
            : {
                right: 'outlook.rights.edit' | translate,
                entry: 'outlook.entries.cases' | translate,
              })
    "
  >
    <button
      kendoButton
      id="outlook-case-ui-caseCard-saveEmail-{{ case.caseNumber }}"
      fillMode="flat"
      [disabled]="
        case.isSaved ||
        !userRights?.customerService?.update ||
        !userRights?.documents?.insert
      "
      (click)="save.emit(case.key)"
      [iconClass]="
        case.isSaved
          ? 'svg-icons icon-saved-email text-neutral-40 icon-20'
          : !userRights?.customerService?.update ||
              !userRights?.documents?.insert
            ? 'maximizer-icons icon-save-email text-neutral-40 icon-24'
            : 'maximizer-icons icon-save-email text-inserv-100 icon-24'
      "
    ></button>
  </span>
</div>

<ng-template #tooltipTemplate let-anchor>
  <p class="caption text-center text-neutral-90 whitespace-nowrap">
    {{ anchor.nativeElement.getAttribute('data-title') }}
  </p>
</ng-template>
