import { Octopus } from '../..';
import { Mapper } from '../../../mappers';
import {
  Field,
  Layout,
  FieldSet,
  Folder,
  SelectorField,
  SelectorMode,
  StringField,
  EntryType,
  DateTimeField,
  NumberField,
  LayoutElement,
  SelectorOption,
  FieldQueryable,
  EntityCatalog,
  FolderCatalog,
} from '../../../models';
import { IdKey, Metadata, MetadataRootFolderKey, Query } from '../models';

export const DefaultLayoutKeyFields = '<default key fields>';

export class MetadataMapper extends Mapper<
  Octopus.MetadataResponse,
  EntityCatalog
> {
  private readonly isDefault: boolean;
  private readonly root: string;
  readonly abentry = Octopus.EntityType[Octopus.EntityType.AbEntry];
  readonly opportunity = Octopus.EntityType[Octopus.EntityType.Opportunity];
  readonly udfPrefix = 'Udf/';

  readonly layoutOverrides: { [key: string]: string[] } = {
    [`/${this.opportunity}/${Octopus.OpportunityFields.Revenue}`]: [
      `/${this.opportunity}/${Octopus.OpportunityFields.ForecastRevenue}`,
      `/${this.opportunity}/${Octopus.OpportunityFields.ActualRevenue}`,
    ],
    [`/${this.opportunity}/${Octopus.OpportunityFields.ActualRevenueCurrency}`]:
      [
        `/${this.opportunity}/${Octopus.OpportunityFields.ActualRevenueCurrency}`,
        `/${this.opportunity}/${Octopus.OpportunityFields.ForecastRevenueCurrency}`,
      ],
  };

  readonly opportunityPredefinedFields: FieldSet = {
    [Octopus.OpportunityFields.ActualRevenueCurrency]:
      this.createCurrencySelector(
        this.opportunity,
        Octopus.OpportunityFields.ActualRevenueCurrency,
      ),
    [Octopus.OpportunityFields.ForecastRevenueCurrency]:
      this.createCurrencySelector(
        this.opportunity,
        Octopus.OpportunityFields.ForecastRevenueCurrency,
      ),
  };
  readonly abEntryNestedFields: string[] = [
    Octopus.AbEntryFields.Address,
    Octopus.AbEntryFields.Email,
    Octopus.AbEntryFields.Email1,
    Octopus.AbEntryFields.Email2,
    Octopus.AbEntryFields.Email3,
    Octopus.AbEntryFields.Phone,
    Octopus.AbEntryFields.Phone1,
    Octopus.AbEntryFields.Phone2,
    Octopus.AbEntryFields.Phone3,
    Octopus.AbEntryFields.Phone4,
    Octopus.EntityFields.SecAccess,
  ];

  readonly nestedFields: Record<string, string[]> = {
    [this.abentry]: this.abEntryNestedFields,
  };
  readonly predefinedFields: Record<string, FieldSet> = {
    [this.opportunity]: this.opportunityPredefinedFields,
  };

  readonly abEntryOverrides: Record<string, Partial<Metadata>> = {
    [Octopus.AbEntryFields.Starred]: {
      Attributes: {
        YesNo: true,
      },
    },
    [Octopus.AbEntryFields.ReportsTo]: {
      Type: Octopus.FieldType.EnumFieldOfStringItem,
    },
  };

  readonly opportunityOverrides: Record<string, Partial<Metadata>> = {
    [Octopus.OpportunityFields.ActualRevenue]: {
      Attributes: {
        MinValue: 0,
        MaxValue: Octopus.OpportunityFieldsMaxValue,
      },
    },
    [Octopus.OpportunityFields.Cost]: {
      Attributes: {
        MinValue: 0,
        MaxValue: Octopus.OpportunityFieldsMaxValue,
      },
    },
    [Octopus.OpportunityFields.ForecastRevenue]: {
      Attributes: {
        MinValue: 0,
        MaxValue: Octopus.OpportunityFieldsMaxValue,
      },
    },
    [Octopus.OpportunityFields.LostToKey]: {
      Type: Octopus.FieldType.EnumFieldOfStringItem,
    },
    [Octopus.OpportunityFields.RevenueType]: {
      Type: Octopus.FieldType.EnumFieldOfStringItem,
    },
  };

  readonly metadataOverrides: Record<
    string,
    Record<string, Partial<Metadata>>
  > = {
    [this.opportunity]: this.opportunityOverrides,
    [this.abentry]: this.abEntryOverrides,
  };

  constructor(private readonly entity: string) {
    super();
    this.root = entity;
    this.isDefault = entity.endsWith('Default');
    if (this.isDefault) {
      this.entity = entity.replace('Default', '');
    }
  }

  from(source: Octopus.CatalogResponse): EntityCatalog {
    const fields = this.mapFields(source.$Metadata.Data);
    const folders = source.SchemaFolder
      ? this.mapFolders(source.SchemaFolder.Data, fields)
      : { all: {}, tree: [] };
    const layouts = this.mapLayouts(
      source.KeyFieldDefinition?.Data ?? [],
      source.KeyFieldList?.Data ?? [],
    );

    return {
      entity: this.entity,
      fields,
      folders,
      layouts,
    };
  }

  mapFolders(source: Octopus.Folder[], fields: FieldSet = {}): FolderCatalog {
    const folders: FolderCatalog = { all: {}, tree: [] };

    source.sort(
      (first, second) =>
        first.ParentFolderKey.localeCompare(MetadataRootFolderKey) ||
        first.SortValue.localeCompare(second.SortValue) ||
        first.Name.localeCompare(second.Name),
    );

    source.forEach((schemaFolder) => {
      const folder: Folder = {
        id: schemaFolder.Key,
        name: schemaFolder.Name,
        index: schemaFolder.SortValue,
        path: schemaFolder.Path,
        appliesTo: schemaFolder.AppliesTo,
        children: [],
        fields: [],
      };

      folders.all[schemaFolder.Key] = folder;

      if (schemaFolder.ParentFolderKey !== MetadataRootFolderKey) {
        const parent = folders.all[schemaFolder.ParentFolderKey];
        if (parent) {
          folder.parent = parent;
          parent.children.push(folder);
        }
      } else {
        folders.tree.push(folder);
      }
    });

    Object.entries(fields).forEach(([key, field]) => {
      const folderKey = field.metadata?.folder;
      if (folderKey && folderKey !== MetadataRootFolderKey) {
        const folder = folders.all[folderKey];
        if (folder) {
          folder.fields.push(key);
        }
      }
    });

    return folders;
  }

  mapLayouts(
    source: Octopus.KeyFieldDefinition[],
    keyFieldList: Octopus.KeyFieldList[],
  ): Layout[] {
    source.sort((first, second) => first.Name.localeCompare(second.Name));

    return source
      .filter((keyField) => keyField.Layout?.length > 0)
      .map((keyField) => {
        const keyFieldListParent = keyFieldList.find(
          (list) => list.Key === keyField.ParentKey,
        );

        return this.mapLayout(keyField, keyFieldListParent);
      });
  }

  mapLayout(
    keyField: Octopus.KeyFieldDefinition,
    keyFieldListParent?: Octopus.KeyFieldList | null,
  ): Layout {
    const appliesTo = this.getAppliesTo(keyField);
    const isDefault =
      keyField.Name.trim().toLowerCase() === DefaultLayoutKeyFields;
    const elements = this.mapColumns(keyField.Layout);
    const key = keyField.Key as IdKey<number>;

    return {
      id: key.Id,
      key: key.Value ?? keyField.Key,
      parentKey: keyFieldListParent?.Key ?? '',
      name: keyField.Name,
      isDefault,
      description: keyFieldListParent?.Description ?? '',
      appliesTo,
      elements,
      target: keyField.Target,
      type: 'row',
      secAccess: {
        read: keyFieldListParent?.SecAccess?.Read[0].Key.Value ?? '',
        write: keyFieldListParent?.SecAccess?.Write[0].Key.Value ?? '',
      },
      secStatus: {
        read: keyFieldListParent?.SecStatus?.CanRead ?? false,
        insert: keyFieldListParent?.SecStatus?.CanCreate ?? false,
        update: keyFieldListParent?.SecStatus?.CanUpdate ?? false,
        delete: keyFieldListParent?.SecStatus?.CanDelete ?? false,
      },
    };
  }

  private getAppliesTo(keyField: Octopus.KeyFieldDefinition): string {
    let appliesTo: string = this.entity;
    if (keyField.Rule) {
      const rule = keyField.Rule;
      if (rule.$AND && rule.$AND.length === 2) {
        const type = (rule.$AND[0] as Query<{ Type: string }>).Type?.$EQ;
        const lead = (rule.$AND[1] as Query<{ Lead: boolean }>).Lead?.$EQ;

        appliesTo = `${type}${lead ? 'Lead' : ''}`;
      }
    }
    return appliesTo;
  }

  private mapColumns(groups: Octopus.KeyFieldGroup[]): LayoutElement[] {
    const layout = groups.reduce(
      (columns, group) => {
        if (!columns[group.ColumnNo]) {
          columns[group.ColumnNo] = [];
        }
        columns[group.ColumnNo].push(this.mapGroup(group));

        return columns;
      },
      {} as Record<number, LayoutElement[]>,
    );

    return Object.values(layout).map((elements) => {
      return {
        type: 'column',
        properties: {
          cssClass: 'w-1/2',
        },
        elements,
      };
    });
  }

  private mapGroup(group: Octopus.KeyFieldGroup): LayoutElement {
    const elements: LayoutElement[] = [];

    group.Element?.forEach((element) => {
      if (element.Id) {
        elements.push(...this.mapGroupField(element));
      } else {
        elements.push({ type: 'separator' });
      }
    });

    return {
      type: 'group',
      name: group.Name,
      elements,
    };
  }

  private mapGroupField(element: Octopus.KeyFieldElement): LayoutElement[] {
    const elements: LayoutElement[] = [];
    const overrides = this.layoutOverrides[element.Id];

    (overrides ?? [element.Id]).forEach((key) => {
      const field = key.replace(`/${this.entity}/`, '');

      elements.push({
        type: 'field',
        field,
      });
    });

    return elements;
  }

  mapMetadata(source: Octopus.EntityWithMetadata): Octopus.Metadata[] {
    let metadata = Object.values(source).map((field) => {
      return field.$Metadata;
    });

    if (this.entity === Octopus.AbEntryEntityCode) {
      const entryType = (source as Octopus.Entity)[
        Octopus.AbEntryFields.Type
      ] as Octopus.ValueAndDisplay<string>;

      if (entryType?.Value) {
        metadata = metadata.filter((field) => {
          return field.AppliesTo.includes(entryType.Value);
        });
      }
    }

    metadata.push(...this.mapNestedMetadata(source));

    return metadata;
  }

  mapNestedMetadata(source: Octopus.EntityWithMetadata): Octopus.Metadata[] {
    const nestedFields = this.nestedFields[this.entity] ?? [];

    return nestedFields.flatMap((field) => {
      return Object.values(
        (source as Octopus.Entity)[field] as Octopus.EntityWithMetadata,
      )
        .map((field) => field?.$Metadata)
        .filter((item) => item);
    });
  }

  mapFields(source: Octopus.Metadata[]): FieldSet {
    source.sort((first, second) =>
      first.Key.Value.localeCompare(second.Key.Value),
    );

    const predefinedFields = this.predefinedFields[this.entity];
    const fields: FieldSet = predefinedFields ? { ...predefinedFields } : {};

    source.forEach((metadata) => this.addFieldToSet(fields, source, metadata));

    return fields;
  }

  private addFieldToSet(
    fields: FieldSet,
    source: Octopus.Metadata[],
    metadata: Octopus.Metadata,
  ): void {
    let key = metadata.Key.Value.replace(`/${this.root}/`, '');
    let parent: Field | undefined;
    let tree: string[] = [];

    if (!key.startsWith(this.udfPrefix)) {
      const keyTree = key.split('/');
      if (keyTree.length > 1) {
        key = keyTree.splice(keyTree.length - 1, 1)[0];
        tree = keyTree;

        keyTree.forEach((fieldKey) => {
          parent = parent?.fields ? parent.fields[fieldKey] : fields[fieldKey];
        });
      }
    }

    this.copyPropertiesFromObjectKey(metadata, source);
    const field = this.mapField(key, tree, metadata);

    if (parent) {
      parent.fields = parent.fields || {};
      field.parentId = parent.id;
      parent.fields[key] = field;
    } else {
      fields[key] = field;
    }
  }

  private copyPropertiesFromObjectKey(
    metadata: Octopus.Metadata,
    source: Octopus.Metadata[],
  ): void {
    if (metadata.Type.endsWith('Object')) {
      const keyMetadata = source.find(
        (field) => field.Key.Value === `${metadata.Key.Value}Key`,
      );
      if (keyMetadata) {
        metadata.ReadOnly = keyMetadata.ReadOnly;
        metadata.Assignable = keyMetadata.Assignable;
        metadata.Mandatory = keyMetadata.Mandatory;
      }
    }
  }

  private mapField(
    key: string,
    tree: string[],
    metadata: Octopus.Metadata,
  ): Field {
    const overrides = this.metadataOverrides[this.entity]?.[key];
    metadata = overrides
      ? {
          ...metadata,
          ...overrides,
          Attributes: { ...metadata.Attributes, ...overrides.Attributes },
        }
      : metadata;

    const common = this.mapFieldCommon(key, metadata, tree);

    switch (metadata.Type) {
      case Octopus.FieldType.AbEntryKey:
      case Octopus.FieldType.AbEntryObject:
        return this.mapSelectorField<string>(common, 'dialog', 'addressBook');

      case Octopus.FieldType.BooleanField:
        return this.mapSelectorField<boolean>(common, 'single', undefined, [
          {
            id: true,
            name: metadata.Attributes?.True?.DisplayValue ?? 'Yes',
            isActive: true,
          },
          {
            id: false,
            name: metadata.Attributes?.False?.DisplayValue ?? 'No',
            isActive: true,
          },
        ]);

      case Octopus.FieldType.CampaignObject:
        return this.mapSelectorField<string>(common, 'dialog', 'campaign');
      case Octopus.FieldType.SecAccessField:
        return this.mapSelectorField<string>(
          common,
          'dialogWithMultiple',
          'secAccess',
        );
      case Octopus.FieldType.CurrencyField:
      case Octopus.FieldType.NumericField:
        return this.mapNumberField(common, metadata);

      case Octopus.FieldType.DateTimeField:
        return this.mapDateTimeField(common, metadata);
      case Octopus.FieldType.TerritoryObject:
      case Octopus.FieldType.EnumFieldOfStringItem:
      case Octopus.FieldType.EnumFieldOfUidKey:
      case Octopus.FieldType.SalesStageSetupObject:
        return this.mapSelectorField<string>(
          common,
          metadata.Attributes?.MultiSelect ? 'multiple' : 'single',
        );

      case Octopus.FieldType.RefIntegerField:
      case Octopus.FieldType.RefLongField:
        return this.mapSelectorField<number>(common, 'single');

      case Octopus.FieldType.SalesProcessSetupObject:
        return this.mapSelectorField<string>(common, 'single', 'salesProcess');

      case Octopus.FieldType.SalesTeamObject:
        return this.mapSelectorField<string>(common, 'single', 'team');

      case Octopus.FieldType.StringField:
        if (metadata.HasOption) {
          return this.mapSelectorField<string>(
            common,
            'suggest',
            undefined,
            [],
            metadata.Attributes?.MaxLength,
          );
        }
        return this.mapStringField(common, metadata);

      case Octopus.FieldType.UidKey:
        return this.mapSelectorField<string>(common, 'single', 'user');
      default:
        return common;
    }
  }

  private mapFieldCommon(
    key: string,
    metadata: Octopus.Metadata,
    tree: string[],
  ): Field {
    return {
      id: key,
      name: metadata.Name,
      description: metadata.Description,
      readonly: metadata.ReadOnly || !metadata.Assignable,
      required: metadata.Mandatory === '',
      metadata: {
        alias: metadata.Alias,
        active: !metadata.Inactive,
        assignable: metadata.Assignable,
        appliesTo: metadata.AppliesTo,
        entity: this.entity,
        expressions: {
          value: metadata.Formula ?? '',
          required: metadata.Mandatory ?? '',
        },
        folder: metadata.Folder,
        hasOptions: metadata.HasOption,
        yesNo: metadata.Attributes?.YesNo ?? false,
        index: metadata.SortValue,
        isCustom: key.startsWith(this.udfPrefix),
        key: metadata.Key.Value,
        nullable: metadata.Nullable,
        sortable: metadata.Sortable,
        queryable: this.mapFieldQueryable(metadata),
        tree,
        type: metadata.Type,
      },
    } as Field;
  }

  private mapFieldQueryable(metadata: Octopus.Metadata): FieldQueryable {
    return {
      equals: metadata.Queryable?.includes('$EQ') ?? false,
      notEquals: metadata.Queryable?.includes('$NE') ?? false,
      contains: metadata.Queryable?.includes('$IN') ?? false,
      notContains: metadata.Queryable?.includes('$NIN') ?? false,
      exists: metadata.Queryable?.includes('$EXISTS') ?? false,
      notExists: metadata.Queryable?.includes('$NEXISTS') ?? false,
      inRange: metadata.Queryable?.includes('$RANGE') ?? false,
      notInRange: metadata.Queryable?.includes('$NRANGE') ?? false,
      greatThan: metadata.Queryable?.includes('$GT') ?? false,
      greatThanOrEqual: metadata.Queryable?.includes('$GE') ?? false,
      lessThan: metadata.Queryable?.includes('$LT') ?? false,
      lessThanOrEqual: metadata.Queryable?.includes('$LE') ?? false,
      like: metadata.Queryable?.includes('$LIKE') ?? false,
      notLike: metadata.Queryable?.includes('$NLIKE') ?? false,
    };
  }

  private mapStringField(
    common: Field,
    metadata: Octopus.Metadata,
  ): StringField {
    return {
      ...common,
      type: 'string',
      maxlength: metadata.Attributes?.MaxLength,
    };
  }

  private mapDateTimeField(
    common: Field,
    metadata: Octopus.Metadata,
  ): DateTimeField {
    const singleSelection = metadata.Attributes?.Date ? 'date' : 'time';
    const dateAndTime = metadata.Attributes?.Date && metadata.Attributes?.Time;

    return {
      ...common,
      type: 'dateTime',
      selection: dateAndTime ? 'dateTime' : singleSelection,
    };
  }

  private mapNumberField(
    common: Field,
    metadata: Octopus.Metadata,
  ): NumberField {
    const decimals = metadata.Attributes?.DecimalPlaces ?? 0;
    let min = metadata.Attributes?.MinValue;
    let max = metadata.Attributes?.MaxValue;

    if (common.metadata?.isCustom) {
      const defaultMax = Number(
        `${'9'.repeat(15 - decimals)}.${'9'.repeat(decimals)}`,
      );
      const defaultMin = -defaultMax;

      min = min ?? defaultMin;
      max = max ?? defaultMax;
    }

    return {
      ...common,
      type: 'number',
      min,
      max,
      decimals,
      currency: metadata.Attributes?.CorporateCode,
    };
  }

  private mapSelectorField<T>(
    common: Field,
    selection: SelectorMode,
    entity?: EntryType,
    options?: SelectorOption<T>[],
    maxlength?: number,
  ): SelectorField<T> {
    return {
      ...common,
      type: 'selector',
      selection,
      options: options ?? [],
      entity,
      allowEmpty: common.metadata?.nullable,
      maxlength,
    };
  }

  private createCurrencySelector(
    entity: string,
    id: string,
  ): SelectorField<string> {
    return {
      id,
      name: '',
      type: 'selector',
      selection: 'single',
      options: [],
      readonly: false,
      metadata: {
        active: true,
        assignable: true,
        appliesTo: [entity],
        entity: entity,
        hasOptions: true,
        yesNo: false,
        key: id,
        nullable: false,
        sortable: false,
        tree: [],
        type: Octopus.FieldType.EnumFieldOfStringItem,
        isCustom: false,
        isPredefined: true,
      },
    };
  }
}
