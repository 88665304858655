import { Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '@maximizer/core/shared/data-access';

@Pipe({
  name: 'format',
})
export class FormatPipe implements PipeTransform {
  constructor(private readonly locale: LocaleService) {}

  transform(format: string, ...args: unknown[]): string {
    return this.locale.format(format, ...args);
  }
}
