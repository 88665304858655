export type StepStatus = 'default' | 'active' | 'completed';
export type InitOnboardingAction = 'start' | 'close';
export type InitOnboardingDialog = 'start' | 'finish';
export type ExitOnboardingAction = 'exit' | 'cancel';

export enum OnboardingStepNumber {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
  Step4 = 4,
}

export enum StepGuideId {
  AddContact1 = 'addContact1',
  AddContact1Alt = 'addContact1Alt',
  AddContact2 = 'addContact2',
  SaveEmail1 = 'saveEmail1',
  SaveEmail2 = 'saveEmail2',
  CreateNote1 = 'createNote1',
  CreateNote2 = 'createNote2',
  CreateNote3 = 'createNote3',
}

export interface Onboarding {
  step: OnboardingStep;
  guides: StepGuide[];
}

export interface OnboardingStep {
  step: OnboardingStepNumber;
  name: string;
  status: StepStatus;
  icon: Icon;
}

export interface Icon {
  name: string;
  type: string;
}

export interface StepGuide {
  stepId: StepGuideId;
  guide: {
    step: number;
    total: number;
  };
  title: string;
  content: string;
  canNavigate: boolean;
  button?: string;
  hideStepCount?: boolean;
}
