import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ContextService } from '@maximizer/core/shared/data-access';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'resourceOrTranslate',
  pure: false,
})
export class ResourceOrTranslatePipe
  extends TranslatePipe
  implements PipeTransform
{
  constructor(
    private readonly context: ContextService,
    translate: TranslateService,
    _ref: ChangeDetectorRef,
  ) {
    super(translate, _ref);
  }

  override transform(key?: string, translateKey?: string): string {
    if (key && this.context.resources?.[key]) {
      return this.context.resources[key];
    }

    return translateKey ? super.transform(translateKey) : '';
  }
}
