import { EmailEntryType, EntryType } from '@maximizer/outlook/shared/domain';
import { TranslateService } from '@ngx-translate/core';

export type PermissionMessage = 'default' | 'admin' | 'generic';

export function getPermissionString(
  translate: TranslateService,
  type: EmailEntryType | 'document' | undefined,
  right: 'edit' | 'create' | undefined,
  message: 'default' | 'admin' | 'generic',
): string {
  const permissionMessage = `outlook.rights.${message}Message`;

  if (type === EntryType.AbEntry) {
    return translate.instant(permissionMessage, {
      right: translate.instant('outlook.rights.' + right),
      entry: translate.instant('outlook.entries.entries'),
    });
  } else if (type === EntryType.Lead) {
    return translate.instant(permissionMessage, {
      right: translate.instant('outlook.rights.' + right),
      entry: translate.instant('outlook.entries.leads'),
    });
  } else if (type === EntryType.Document) {
    return translate.instant(permissionMessage, {
      right: translate.instant('outlook.rights.' + right),
      entry: translate.instant('outlook.entries.emailLogs').toLowerCase(),
    });
  } else {
    return translate.instant('outlook.rights.' + permissionMessage);
  }
}
