import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { TranslateModule } from '@ngx-translate/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { LabelModule } from '@progress/kendo-angular-label';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IconsModule } from '@progress/kendo-angular-icons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { RouterModule } from '@angular/router';
import { TooltipsModule, PopoverModule } from '@progress/kendo-angular-tooltip';
import { OutlookReadComponent } from './read/read.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { PopupModule } from '@progress/kendo-angular-popup';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { OutlookMenuUiModule } from '@maximizer/outlook/menu/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { OutlookTimelineUiModule } from '@maximizer/outlook/timeline/ui';
import { OutlookOpportunityUiModule } from '@maximizer/outlook/opportunity/ui';
import { OutlookTaskUiModule } from '@maximizer/outlook/task/ui';
import { OutlookNoteUiModule } from '@maximizer/outlook/note/ui';
import { OutlookAppointmentUiModule } from '@maximizer/outlook/appointment/ui';
import { CoreGlobalSearchDataAccessModule } from '@maximizer/core/global-search/data-access';
import {
  OutlookComposeComponent,
  OutlookEntryListItemComponent,
  OutlookComposeAddEmailComponent,
  OutlookComposeSaveToEmailComponent,
} from './compose';
import { OutlookRelatedComponent } from './related/related.component';
import { OutlookCaseUiModule } from '@maximizer/outlook/case/ui';
import { OutlookOnboardingUiModule } from '@maximizer/outlook/onboarding/ui';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@NgModule({
  declarations: [
    OutlookReadComponent,
    OutlookComposeComponent,
    OutlookEntryListItemComponent,
    OutlookComposeAddEmailComponent,
    OutlookComposeSaveToEmailComponent,
    OutlookRelatedComponent,
  ],
  exports: [
    OutlookReadComponent,
    OutlookComposeComponent,
    OutlookEntryListItemComponent,
    OutlookComposeAddEmailComponent,
    OutlookComposeSaveToEmailComponent,
    OutlookRelatedComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule,
    InputsModule,
    DropDownsModule,
    LabelModule,
    LayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    IntlModule,
    DateInputsModule,
    FormFieldModule,
    IconsModule,
    ProgressBarModule,
    IndicatorsModule,
    RouterModule,
    ListViewModule,
    PopupModule,
    UiModule,
    OutlookSharedDomainModule,
    OutlookSharedDataAccessModule,
    OutlookSharedUiModule,
    OutlookMenuUiModule,
    OutlookTimelineUiModule,
    OutlookOpportunityUiModule,
    OutlookTaskUiModule,
    OutlookNoteUiModule,
    OutlookAppointmentUiModule,
    CoreGlobalSearchDataAccessModule,
    TooltipsModule,
    OutlookCaseUiModule,
    OutlookOnboardingUiModule,
    PopoverModule,
    DialogsModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookMainUiModule {}
