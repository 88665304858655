import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmail } from '@maximizer/shared/util';

export class StringValidator {
  static NotEmpty(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const trimmedValue = (control?.value ?? '').trim();
      return trimmedValue == '' ? { emptyValue: true } : null;
    };
  }
  static ValidEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value ? isEmail(control.value) : true;
      return isValid ? null : { format: true };
    };
  }
}
