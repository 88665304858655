import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormFieldModule, InputsModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import {
  UiModule,
  defaultTranslateConfiguration,
} from '@maximizer/core/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { OutlookSharedDataAccessModule } from '@maximizer/outlook/shared/data-access';
import { AddNoteComponent } from './add-note/add-note.component';
import { SummaryNoteComponent } from './summary-note/summary-note.component';
import { DataAccessModule } from '@maximizer/core/shared/data-access';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookMenuUiModule } from '@maximizer/outlook/menu/ui';
import { OutlookSharedUiModule } from '@maximizer/outlook/shared/ui';
import { TooltipsModule, PopoverModule } from '@progress/kendo-angular-tooltip';
import { OutlookOnboardingUiModule } from '@maximizer/outlook/onboarding/ui';
import { DialogsModule } from '@progress/kendo-angular-dialog';

@NgModule({
  declarations: [AddNoteComponent, SummaryNoteComponent],
  exports: [AddNoteComponent, SummaryNoteComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    IntlModule,
    LabelModule,
    ButtonsModule,
    DateInputsModule,
    FormFieldModule,
    ReactiveFormsModule,
    DropDownsModule,
    IconsModule,
    FormsModule,
    InputsModule,
    ProgressBarModule,
    IndicatorsModule,
    OutlookSharedDataAccessModule,
    OutlookSharedDomainModule,
    OutlookSharedUiModule,
    OutlookMenuUiModule,
    DataAccessModule,
    UiModule,
    TooltipsModule,
    PopoverModule,
    OutlookOnboardingUiModule,
    DialogsModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class OutlookNoteUiModule {}
