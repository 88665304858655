import {
  startOfWeek,
  endOfDay,
  subWeeks,
  addWeeks,
  nextSaturday,
  isSameDay,
  addDays,
} from 'date-fns';
import { EntityEvent } from './entity';
import { InteractionEvent, InteractionType } from './interaction';
import { ListItem } from './list-item';
import { RelatedEntry } from './related-entry';
import { Pagination } from './pagination';
import { SecurityStatus } from '../api/octopus';

export interface TimelineMonthGroup {
  items: Array<TimelineEvent>;
  groupDate: string;
  rawDate: Date;
  suffixTitle: string;
}
export interface TimelineHistoricDetailsEvent {
  key: string;
  subject?: string;
  description?: string;
  descriptionRichText?: string;
  isRichText: boolean;
}

export interface TimelineEvent extends EntityEvent {
  subject?: string;
  result?: string;
  source?: string;
  regarding?: RelatedEntry;
  description?: string;
  descriptionRichText?: string;
  important?: boolean;
  date: { value: Date | string; display: string };
  endDate?: { value: Date | string; display: string };
  priority?: string;
  private?: boolean;
  secStatus?: SecurityStatus;
  withs?: RelatedEntry[];
  users?: RelatedEntry[];
  category?: string;
  creator: { value: string; display: string };
  isRichText?: boolean;
}

export interface TimelineHistoricPagination extends Pagination {
  data: TimelineEvent[];
}

export interface InteractionTimelineEvent
  extends TimelineEvent,
    InteractionEvent {}

export interface BaseAppointmentTimelineEvent {
  isOverdue: boolean;
  isDone: boolean;
  hasTime: boolean;
  attendees: RelatedEntry[];
}
export interface AppointmentTimelineEvent
  extends BaseAppointmentTimelineEvent,
    TimelineEvent {}
export interface BaseTaskTimelineEvent {
  isOverdue: boolean;
  isDone: boolean;
  hasTime: boolean;
  assignedTo: RelatedEntry[];
}
export interface TaskTimelineEvent
  extends BaseTaskTimelineEvent,
    TimelineEvent {}

export interface BaseNoteTimelineEvent {
  noteType: number;
}
export interface NoteTimelineEvent
  extends BaseNoteTimelineEvent,
    TimelineEvent {}
export interface BaseEmailTimelineEvent {
  attachments: string[];
}
export interface EmailTimelineEvent
  extends BaseEmailTimelineEvent,
    TimelineEvent {}
export interface BaseDocumentTimelineEvent {
  file: string;
  documentType: number;
}
export interface DocumentTimelineEvent
  extends BaseDocumentTimelineEvent,
    TimelineEvent {}

export type TimelineWeekType = 'last' | 'current' | 'next' | 'range';

export class TimelineWeek {
  days: TimelineDay[] = [];

  constructor(
    public type: TimelineWeekType,
    public dates: TimelineWeekDates,
    public source: TimelineEvent[],
  ) {
    const events = source.filter(
      (event) =>
        event.date.value >= dates.start && event.date.value <= dates.end,
    );

    let current = dates.start;
    while (current <= dates.end) {
      this.days.push({
        date: current,
        events: events.filter((event) => isSameDay(event.date.value, current)),
      });

      current = addDays(current, 1);
    }
  }

  get total(): number {
    if (this.days?.length) {
      return this.days.reduce((total, day) => total + day.events.length, 0);
    }
    return 0;
  }
}

export interface TimelineDay {
  date: Date;
  events: TimelineEvent[];
}

export interface TimelineConfiguration {
  categories: ListItem<string>[];
  types: InteractionType[];
  notes: ListItem<number>[];
  documents: ListItem<number>[];
}

export interface TimelineWeekDates {
  start: Date;
  end: Date;
}

export interface TimelineWeekRange {
  last: TimelineWeekDates;
  current: TimelineWeekDates;
  next: TimelineWeekDates;
}

export class Timeline {
  private days: TimelineDay[];

  constructor(
    public range: TimelineWeekRange,
    public weeks: TimelineWeek[],
  ) {
    this.days = this.weeks
      .map((week) => week.days)
      .reduce((allDays, days) => {
        return allDays.concat(days);
      }, []);
  }

  inRange(event: TimelineEvent): boolean {
    return (
      event.date.value >= this.range.last.start &&
      event.date.value <= this.range.next.end
    );
  }

  update(event: TimelineEvent): void {
    const currentDay = this.days.find((day) =>
      day.events.find((item) => item.key === event.key),
    );
    if (currentDay) {
      const keepDay = isSameDay(currentDay.date, event.date.value);
      const index = currentDay.events.findIndex(
        (item) => item.key === event.key,
      );

      if (keepDay) {
        currentDay.events.splice(index, 1, event);
      } else {
        currentDay.events.splice(index, 1);
        this.add(event);
      }
    } else {
      this.add(event);
    }
  }

  add(event: TimelineEvent): void {
    const inRange = this.inRange(event);
    if (inRange) {
      const targetDay = this.days.find((day) =>
        isSameDay(day.date, event.date.value),
      );
      targetDay?.events.push(event);
    }
  }
}

export interface InteractionListItem extends ListItem<string | number> {
  key: string;
  icon: string;
  items?: InteractionListItem[];
  type: 'interaction' | 'note';
  tag?: string;
}

export function getTimelineWeekRange(reference?: Date): TimelineWeekRange {
  if (!reference) {
    reference = new Date();
  }
  const weekStartDay = startOfWeek(reference, { weekStartsOn: 0 });
  const weekEndDay = endOfDay(nextSaturday(weekStartDay));

  return {
    last: {
      start: subWeeks(weekStartDay, 1),
      end: subWeeks(weekEndDay, 1),
    },
    current: {
      start: weekStartDay,
      end: weekEndDay,
    },
    next: {
      start: addWeeks(weekStartDay, 1),
      end: addWeeks(weekEndDay, 1),
    },
  };
}

export enum TimelineColumnField {
  Withs = 'withs',
  Subject = 'subject',
  Regarding = 'regarding',
}
