import { Mapper } from '../../../mappers';
import { Column, CustomGrid } from '../../../models';

export class CustomGridMapper extends Mapper<CustomGrid[], CustomGrid[]> {
  constructor(private readonly culumns: Column[]) {
    super();
  }
  override from(source: CustomGrid[]): CustomGrid[] {
    return source.map((item) => {
      return this.mapItem(item);
    });
  }
  mapItem(source: CustomGrid): CustomGrid {
    const data: CustomGrid = {};
    this.culumns.forEach((column) => {
      data[column.id] = source[column.field];
    });
    return data;
  }
}
