import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, map, of } from 'rxjs';
import { OpenAI } from '@maximizer/core/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class OpenAiService {
  readonly completionsUrl = 'https://api.maximizer.com/ai/v1/chat/completions';

  constructor(private readonly http: HttpClient) {}

  getSmartQuestions(
    message: OpenAI.GPTMessage[],
    maxTokens: number,
    temperature: number,
  ): Observable<string> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Model', 'gpt-3.5-turbo');
    const requestBody: OpenAI.CompletionRequest = {
      messages: message,
      model: 'gpt-3.5-turbo',
      max_completion_tokens: maxTokens,
      temperature: temperature,
    };
    return this.http
      .post<OpenAI.CompletionResponse>(this.completionsUrl, requestBody, {
        headers: headers,
      })
      .pipe(
        map((response: OpenAI.CompletionResponse) => {
          return response.choices[0].message.content;
        }),
      );
  }

  summarize(
    texts: string[],
    model = 'gpt-3.5-turbo',
    maxTokens: number | null = null,
    temperature = 0.2,
  ): Observable<OpenAI.SummarizationResponse | null> {
    const userMessages: OpenAI.GPTMessage[] = texts.map((note) => ({
      role: 'user',
      content: note,
    }));

    const systemMessage: OpenAI.GPTMessage = {
      role: 'system',
      content:
        'Please summarize the above texts concisely while keeping the original tone. Create a response in JSON format with the following structure { "summaries": [{ "dateTime": The date and time, "content": The summary of the text }], "summary": The summary of all texts, "recommendations": [{ "type": "Email or Task or Meeting or Call", "title", "content" }] }. Provide one to three recommendations.',
    };

    const request: OpenAI.CompletionRequest = {
      messages: [...userMessages, systemMessage],
      model,
      max_completion_tokens: maxTokens,
      temperature,
      response_format: { type: 'json_object' },
    };

    return this.http
      .post<OpenAI.CompletionResponse>(this.completionsUrl, request)
      .pipe(
        map((response) => {
          return JSON.parse(response.choices[0].message.content);
        }),
        catchError(() => of(null)),
      );
  }
}
