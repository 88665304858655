<div class="pt-2 pb-4" [@fadeIn]>
  <div class="flex justify-between pb-2">
    <p class="text-body text-neutral-70">
      @if (!guide.hideStepCount) {
        {{ guide.guide.step }} {{ 'outlook.onboarding.of' | translate }}
        {{ guide.guide.total }}
      }
    </p>
    <p
      id="outlook-onboarding-ui-step-guide-exit-{{ guide.stepId }}"
      (click)="exit.emit(guide.stepId)"
      class="text-body text-neutral-70 hover:underline hover:cursor-pointer"
    >
      {{ 'outlook.buttons.exit' | translate }}
    </p>
  </div>
  <p class="text-body bold">
    {{ guide.title | translate }}
  </p>
  <p class="text-body">
    {{ guide.content | translate }}
  </p>
  @if (guide.canNavigate) {
    <button
      kendoButton
      tabindex="-1"
      themeColor="primary"
      class="mt-2 w-full"
      (click)="next.emit(onboardingStep)"
    >
      {{ guide.button ?? 'outlook.buttons.next' | translate }}
    </button>
  }
</div>
