import { Injectable } from '@angular/core';
import { InsightsService } from '@maximizer/core/shared/insights';
import { getDecodedAccessToken } from '../utils/datacenter.util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { OutlookInsightsEvents } from '../utils/insights.util';
import { OnboardingStepNumber, StepGuideId } from '../models';
import { OnboardingStore } from '../state';

@Injectable()
export class OnboardingService {
  constructor(
    private readonly insightsService: InsightsService,
    private readonly onboardingStore: OnboardingStore,
  ) {}

  reset(): void {
    this.onboardingStore.setCurrentStep(OnboardingStepNumber.Step1);
    this.onboardingStore.setCurrentGuide(StepGuideId.AddContact1);
    this.onboardingStore.updateCanNavigate(StepGuideId.AddContact1, false);
    this.onboardingStore.updateCanNavigate(StepGuideId.SaveEmail1, false);
    this.onboardingStore.updateCanNavigate(StepGuideId.CreateNote1, false);
    this.onboardingStore.updateCanNavigate(StepGuideId.CreateNote2, false);
  }

  trackOnboardingEvent(
    name: string,
    eventId: OutlookInsightsEvents,
    token: string,
    alias?: string,
    stepId?: string,
    error?: string,
  ): void {
    const decodedToken = getDecodedAccessToken(token);
    const customProperties = {
      error: error ?? '',
      decodedToken,
      databaseAlias: alias ?? '',
      eventId,
      stepId,
    };

    this.insightsService.trackEvent(
      { name },
      SeverityLevel.Information,
      customProperties,
    );
  }
}
