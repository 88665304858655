import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  Entity,
  FieldSet,
  RuleSet,
  SelectorField,
  StringField,
} from '@maximizer/core/shared/domain';
import { Subject } from 'rxjs';
import { KendoComponent } from '../../kendo/models/kendo-component';
import { LayoutFormControl } from './layout-form-control';
import {
  ValueHandler,
  OctopusValueHandler,
  RuleSetHandler,
  OctopusRuleSetHandler,
} from '../handler';
import { StringValidator } from '../../validators';

export interface OpenArguments {
  control: LayoutFormControl;
  field: SelectorField<unknown> | StringField;
}

export class LayoutFormGroup extends FormGroup {
  valueHandler: ValueHandler;
  ruleSetHandler: RuleSetHandler;
  pickerOpen = new Subject<OpenArguments>();
  customDialogOpen = new Subject<OpenArguments>();
  setLayout = new Subject<string>();
  disableLayoutSelector = new Subject<boolean>();
  components: KendoComponent[] = [];

  constructor(
    public fields: FieldSet,
    readonly ruleSet: RuleSet | null = null,
  ) {
    const controls: { [key: string]: FormControl | FormGroup } = {};
    for (const key of Object.keys(fields)) {
      const field = fields[key];
      if (!field.fields) {
        controls[key] = new LayoutFormControl(field);
      } else {
        controls[key] = new LayoutFormGroup(field.fields);
      }
    }
    super(controls);
    this.valueHandler = new OctopusValueHandler(this);
    this.ruleSetHandler = new OctopusRuleSetHandler(this, ruleSet);
  }

  applyRuleSet(): void {
    this.ruleSetHandler.applyRuleSet();
  }

  openPicker(field: SelectorField<unknown>): void {
    const control = this.getControl(field.id);
    if (control) {
      this.pickerOpen.next({ control, field });
    }
  }

  openCustomDialog(field: StringField): void {
    const control = this.getControl(field.id);
    if (control) {
      this.customDialogOpen.next({ control, field });
    }
  }

  getControl(id: string): LayoutFormControl | null {
    return this.get(id) as LayoutFormControl;
  }

  getGroup(id: string): LayoutFormGroup | null {
    return this.get(id) as LayoutFormGroup;
  }

  getErrors(): Entity {
    const errors: Entity = {};
    for (const name in this.controls) {
      const control = this.controls[name];
      if (control.errors) {
        errors[name] = control.errors;
      }
    }
    return errors;
  }

  setRequiredControl(id: string, required: boolean): void {
    let control = this.getControl(id);
    if (control) {
      control.field.required = required;
      if (control.field.metadata?.type?.endsWith('Key')) {
        const objectField = id.substring(0, id.length - 3);
        const objectControl = this.getControl(objectField);
        if (objectControl) {
          objectControl.updateField({ required });
          control = objectControl;
        }
      }
      if (required) {
        if (
          control.field.type === 'string' ||
          (control.field as SelectorField<string>).selection === 'suggest'
        ) {
          control.addValidators([StringValidator.NotEmpty()]);
        } else {
          control.addValidators(Validators.required);
        }
      } else {
        control.removeValidators(Validators.required);
      }
      control.markAsDirty();
      control.updateValueAndValidity({ emitEvent: false });
    }
  }

  getControlOrRelated(id: string): LayoutFormControl | null {
    const control = this.getControl(id);
    let relatedControl: LayoutFormControl | null = null;
    if (control) {
      if (control.field.metadata?.type?.endsWith('Key')) {
        const objectField = id.substring(0, id.length - 3);
        relatedControl = this.getControl(objectField);
      }
    }
    return relatedControl ?? control;
  }

  focusFirstInvalidControl(): void {
    this.components
      .find((component) => component.formControl?.invalid)
      ?.focus();
  }

  focusControl(id: string): void {
    this.components
      .find(
        (component) =>
          component.formControl instanceof LayoutFormControl &&
          component.formControl?.name === id,
      )
      ?.focus();
  }

  mergeWith(sourceForm: LayoutFormGroup) {
    Object.keys(this.controls).forEach((key) => {
      const targetControl = this.get(key);
      const sourceControl = sourceForm.get(key);
      if (targetControl && sourceControl) {
        if (
          targetControl instanceof LayoutFormControl &&
          sourceControl instanceof LayoutFormControl
        ) {
          if (!targetControl.hasValue && sourceControl.hasValue) {
            if (sourceControl.field.type === 'selector') {
              const sourceSelector =
                sourceControl.field as SelectorField<unknown>;
              targetControl.setOptions(sourceSelector.options);
            }
            targetControl.setValue(sourceControl.value);
          }
        } else if (
          targetControl instanceof LayoutFormGroup &&
          sourceControl instanceof LayoutFormGroup
        ) {
          targetControl.mergeWith(sourceControl);
        }
      }
    });
  }
}
