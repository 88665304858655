<maximizer-outlook-menu
  [id]="id"
  [pageType]="type"
  [pageTitle]="pageTitle"
  (exit)="exitOnboardingGuide()"
></maximizer-outlook-menu>

<div
  #entryPageContainer
  class="h-full w-full text-xs flex flex-col scrollbar"
  (scroll)="onScroll()"
  [ngClass]="
    onboardingStore.inProgress() ? 'pointer-events-none overflow-hidden' : ''
  "
>
  @if (!loading.skeleton && loading.saveEmail) {
    <maximizer-loader type="legacy"></maximizer-loader>
  } @else if (loading.skeleton) {
    <div class="sticky top-0 my-3 mx-6">
      <div class="flex">
        <maximizer-outlook-skeleton
          containerClasses="h-12 w-12 max-w-12 rounded-full bg-neutral-20 mr-4"
          fullWidth="none"
        ></maximizer-outlook-skeleton>
        <maximizer-outlook-skeleton
          class="mt-auto"
          containerClasses="h-8 w-full rounded-md bg-neutral-20"
          fullWidth="full"
        ></maximizer-outlook-skeleton>
      </div>
      <maximizer-outlook-skeleton
        class="my-4"
        containerClasses="h-8 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <maximizer-outlook-skeleton
        class="mb-4"
        containerClasses="h-4 w-full rounded-md bg-neutral-20"
        fullWidth="full"
      ></maximizer-outlook-skeleton>
      <div class="flex mb-4 justify-around">
        @for (item of [0, 1, 2, 3, 4]; track $index) {
          <div class="flex">
            <maximizer-outlook-skeleton
              containerClasses="h-8 w-8 max-w-8 rounded-full bg-neutral-20"
              fullWidth="none"
            ></maximizer-outlook-skeleton>
          </div>
        }
      </div>
    </div>
  }

  @if (!loading.skeleton) {
    <div class="sticky top-0 mt-3 mx-6">
      <!-- Entry row -->
      <div class="flex justify-between items-start py-1">
        <div class="flex">
          <a
            class="flex mr-2 my-auto no-underline"
            target="_blank"
            [href]="deepLink"
          >
            <div
              class="avatar avatar-lg avatar-neutral-30 avatar-border text-xl text-black font-bold"
              [ngClass]="
                abentry &&
                (abentry.type === 'Individual' || abentry.type === 'Company')
                  ? 'avatar-square'
                  : ''
              "
            >
              <span
                class="subtitle-1"
                *ngIf="abentry && abentry.type !== 'Company'"
                >{{ abentry.name | nameInitials }}</span
              >
              <span
                *ngIf="lead"
                class="fa-icons icon icon-solid icon-18 icon-user-magnifying-glass cursor-pointer m-auto"
              ></span>

              <span
                *ngIf="abentry && abentry.type === 'Company'"
                class="fa-icons icon icon-regular icon-22 icon-building m-auto cursor-pointer"
              ></span>
            </div>
          </a>

          <div class="flex flex-col py-1">
            <span class="subtitle-1 break-all">{{
              abentry?.name ?? lead?.name
            }}</span>
            <span class="subtitle-2 break-all"
              >{{ abentry?.position ?? lead?.position }}
            </span>
            @if (!abentry?.parentKey) {
              <span class="break-all no-underline">{{
                abentry?.companyName ?? lead?.companyName
              }}</span>
            } @else {
              <div
                kendoTooltip
                position="bottom"
                [tooltipWidth]="200"
                [tooltipTemplate]="openInMaximizerTooltip"
              >
                <div class="hover:shadow caption break-all">
                  <a
                    [href]="companyDeepLink"
                    target="_blank"
                    title="Link to company"
                    class="text-body hover:underline text-inserv-100 no-underline"
                    >{{ abentry?.companyName }}</a
                  >
                </div>
              </div>
            }
          </div>

          <ng-template #openInMaximizerTooltip>
            <span class="caption text-neutral-90">{{
              'outlook.entryPage.openCompanyInMaximizer' | translate
            }}</span>
          </ng-template>
        </div>

        @if (abentry?.type !== 'Company') {
          <div
            kendoTooltip
            position="bottom"
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            position="top"
            [callout]="false"
          >
            @if (lead) {
              <span
                [title]="
                  userRights?.lead?.update
                    ? lead.status === leadStatus.Archived
                      ? ('outlook.entryPage.archivedTooltip' | translate)
                      : ('outlook.buttons.edit' | translate)
                    : ('outlook.rights.adminMessage'
                      | translate
                        : {
                            right: 'outlook.rights.edit' | translate,
                            entry: 'outlook.entries.leads' | translate,
                          })
                "
              >
                <button
                  kendoButton
                  id="outlook-entry-ui-entryPage-edit"
                  [queryParams]="{ entryType }"
                  [routerLink]="
                    lead.status === leadStatus.Archived ? null : 'edit'
                  "
                  [disabled]="
                    lead.status === leadStatus.Archived ||
                    !userRights?.lead?.update
                  "
                  fillMode="flat"
                  iconClass="fa-icons icon-solid icon-14 px-2 icon-pencil"
                ></button>
              </span>
            } @else {
              <span
                [title]="
                  userRights?.addressBook?.update
                    ? ('outlook.buttons.edit' | translate)
                    : ('outlook.rights.adminMessage'
                      | translate
                        : {
                            right: 'outlook.rights.edit' | translate,
                            entry: 'outlook.entries.entries' | translate,
                          })
                "
              >
                <button
                  kendoButton
                  id="outlook-entry-ui-entryPage-edit"
                  [queryParams]="{ entryType }"
                  [routerLink]="'edit'"
                  [disabled]="!userRights?.addressBook?.update"
                  fillMode="flat"
                  iconClass="fa-icons icon-solid icon-14 px-2 icon-pencil"
                ></button>
              </span>
            }
          </div>
        }
      </div>

      <!-- Info row -->
      <div class="py-3">
        @if (loading.skeleton) {
          <maximizer-loader type="legacy"></maximizer-loader>
        }

        @if (abentry && userRights?.opportunities?.read) {
          <div class="flex">
            <span class="text-caption text-neutral-90"
              >{{ 'outlook.entryPage.currentOpportunities' | translate }}:
            </span>
            <span class="text-caption text-neutral-70 ml-2">{{
              opportunities?.current ?? ''
            }}</span>
          </div>
          <div class="flex">
            <span class="text-caption text-neutral-90"
              >{{ 'outlook.entryPage.completedOpportunities' | translate }}:
            </span>
            <span class="text-caption text-neutral-70 ml-2">{{
              opportunities?.completed ?? ''
            }}</span>
          </div>
        }

        @if (abentry?.type !== 'Company') {
          <div class="flex">
            <span class="text-caption text-neutral-90"
              >{{ 'outlook.entryPage.lastContactedDate' | translate }}:
            </span>
            <span
              class="text-caption text-neutral-70 ml-2"
              *ngIf="
                lastContactDays === null || lastContactDays >= 0;
                else futureDate
              "
              >{{
                lastContactMessage ?? '' | translate: { days: lastContactDays }
              }}</span
            >

            <ng-template #futureDate>
              <span class="text-caption text-neutral-70 ml-2">
                {{ abentry?.lastContactDateValue | date: 'MMM dd, YYYY' }}
              </span>
            </ng-template>
          </div>
        }

        @if (lead && lead.status === 3) {
          <div class="flex">
            <span class="text-caption text-neutral-90"
              >{{ 'outlook.entryPage.archivedDate' | translate }}:
            </span>
            <span class="text-caption text-neutral-70 ml-2">{{
              lead.archivedDate | date: 'MMM dd, YYYY'
            }}</span>
          </div>
        }
      </div>

      <!-- Auto-Save Email Toggle -->
      @if (outlookStore.versionValidForNewSync()) {
        @if (showAutoSaveEmailToggle) {
          <div class="flex gap-1.5 items-center pb-3">
            <span
              kendoTooltip
              [tooltipTemplate]="linkToAppStoreTooltip"
              [tooltipWidth]="250"
              [callout]="false"
              position="bottom"
              [closable]="true"
              [showOn]="outlookSyncInstalled ? 'none' : 'click'"
            >
              <kendo-switch
                kendoTooltip
                id="outlook-entry-ui-entryPage-autoSave"
                size="small"
                [(ngModel)]="autoSave"
                [disabled]="
                  autoSaveDisabled || loading.sync || !emailSaveUserRights
                "
                (valueChange)="onAutoSaveEmailChange($event)"
                [tooltipTemplate]="
                  !emailSaveUserRights || !autoSaveDisabled
                    ? tooltipTemplate
                    : autoSaveDisabledTooltip
                "
                [tooltipWidth]="
                  autoSaveDisabled || !emailSaveUserRights ? 210 : 115
                "
                [callout]="false"
                [title]="
                  emailSaveUserRights
                    ? autoSaveDisabled
                      ? null
                      : ('outlook.entryPage.autoSave.label' | translate)
                    : getSaveEmailPermissionMessage(type, 'admin')
                "
              ></kendo-switch>
            </span>
            <p class="text-caption text-neutral-90">
              {{ 'outlook.entryPage.autoSave.label' | translate }}
            </p>
            <span
              kendoTooltip
              class="fa-icons icon-solid icon-12 icon-circle-question text-neutral-90"
              title="{{ 'outlook.entryPage.autoSave.help' | translate }}"
              [tooltipWidth]="250"
              [callout]="false"
              [tooltipTemplate]="autoSaveTooltip"
            ></span>
            @if (loading.sync) {
              <kendo-loader
                class="pl-4"
                type="pulsing"
                themeColor="primary"
                size="small"
              ></kendo-loader>
            }
          </div>

          <ng-template #autoSaveDisabledTooltip>
            <div class="ml-2 offset-left-tooltip">
              <p class="text-body">
                {{ 'outlook.entryPage.autoSave.disabled' | translate }}
              </p>
            </div>
          </ng-template>

          <ng-template #linkToAppStoreTooltip>
            <div class="pt-2 flex flex-col gap-2 ml-2 offset-left-tooltip">
              <p class="subtitle-2">
                {{ 'outlook.entryPage.autoSave.newFeature' | translate }}
              </p>
              <p class="text-body">
                {{ 'outlook.entryPage.autoSave.installation' | translate }}
              </p>
              <p
                class="text-body"
                [innerHtml]="
                  'outlook.entryPage.autoSave.installationNote' | translate
                "
              ></p>
              <div class="flex justify-end">
                <button
                  kendoButton
                  themeColor="primary"
                  (click)="buildAppStoreFormRequest()"
                >
                  {{ 'outlook.buttons.install' | translate }}
                </button>
              </div>
            </div>
          </ng-template>

          <ng-template #autoSaveTooltip>
            <p class="text-body">
              {{ 'outlook.entryPage.autoSave.tooltip' | translate }}
            </p>
          </ng-template>
        } @else if (loading.earlyAccess) {
          <maximizer-outlook-skeleton
            class="pb-3"
            containerClasses="h-6 w-full rounded-md bg-neutral-20"
            fullWidth="full"
          ></maximizer-outlook-skeleton>
        } @else if (showEarlyAccessRequest) {
          <div
            class="flex flex-col gap-2 bg-marketing-mint py-2 px-4 mb-3 rounded"
          >
            <div class="flex justify-between">
              <p class="text-body bold">
                {{ 'outlook.earlyAccess.title' | translate }}
              </p>
              <span
                class="fa-icons icon-xmark icon-14 hover:cursor-pointer"
                (click)="hideEarlyAccessRequest()"
              ></span>
            </div>
            <p class="text-body">
              {{ 'outlook.earlyAccess.message' | translate }}
            </p>
            <div class="flex justify-end">
              <button
                kendoButton
                id="outlook-entry-ui-entryPage-install"
                fillMode="flat"
                themeColor="primary"
                class="text-button"
                (click)="sendEarlyAccessRequest()"
              >
                {{ 'outlook.earlyAccess.request' | translate }}
              </button>
            </div>
          </div>
        } @else {
          <p class="pb-3 text-body text-neutral-90">
            {{ 'outlook.earlyAccess.comingSoon' | translate }}
          </p>
        }
      }

      <!-- Actions row -->
      <div
        class="action-buttons flex pb-2"
        [ngClass]="lead ? 'justify-around' : 'justify-between'"
      >
        <!-- Save Email -->
        @if (showSaveEmail) {
          @if (emailIsSaved) {
            <div
              kendoTooltip
              id="outlook-entry-ui-entryPage-emailSaved"
              [tooltipTemplate]="tooltipTemplate"
              position="bottom"
              [callout]="false"
              class="rounded-full w-8 h-8 flex bg-neutral-30 justify-center items-center"
              [title]="'outlook.main.tooltip.email-saved' | translate"
            >
              <span
                class="maximizer-icons icon-saved-email text-max-white icon-16 pt-0.5"
              ></span>
            </div>
          } @else {
            <div
              kendoTooltip
              id="outlook-entry-ui-entryPage-saveEmail"
              [tooltipTemplate]="tooltipTemplate"
              position="bottom"
              [callout]="false"
              (click)="saveEmailForEntry()"
              class="rounded-full w-8 h-8 flex justify-center items-center"
              [ngClass]="
                disabled || !getSaveEmailPermission(type)
                  ? 'bg-neutral-30'
                  : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
              "
              [title]="
                getSaveEmailPermission(type)
                  ? ('outlook.main.tooltip.save-email' | translate)
                  : getSaveEmailPermissionMessage(type, 'default')
              "
            >
              <span
                class="maximizer-icons icon-save-email text-max-white icon-18"
              ></span>
            </div>
          }
        }

        @if (abentry) {
          <!-- Add Opportunity -->
          <div
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            position="bottom"
            [callout]="false"
            id="outlook-entry-ui-entryPage-addOpportunity"
            [routerLink]="
              disabled || !userRights?.opportunities?.insert
                ? null
                : 'add-opportunity'
            "
            class="rounded-full w-8 h-8 pl-0.5 flex justify-center items-center"
            [ngClass]="
              disabled || !userRights?.opportunities?.insert
                ? 'bg-neutral-30'
                : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
            "
            [title]="
              userRights?.opportunities?.insert
                ? ('outlook.opportunity.add-title' | translate)
                : ('outlook.rights.defaultMessage'
                  | translate
                    : {
                        right: 'outlook.rights.create' | translate,
                        entry: 'outlook.entries.opportunities' | translate,
                      })
            "
          >
            <span
              class="maximizer-icons icon-add-opportunity text-max-white icon-16"
            ></span>
          </div>
          <!-- Add Case -->
          <div
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            position="bottom"
            [callout]="false"
            id="outlook-entry-ui-entryPage-addCase"
            [routerLink]="
              disabled || !userRights?.customerService?.insert
                ? null
                : 'add-case'
            "
            class="rounded-full w-8 h-8 flex items-center justify-center"
            [ngClass]="
              disabled || !userRights?.customerService?.insert
                ? 'bg-neutral-30'
                : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
            "
            [title]="
              userRights?.customerService?.insert
                ? ('outlook.case.add' | translate)
                : ('outlook.rights.defaultMessage'
                  | translate
                    : {
                        right: 'outlook.rights.create' | translate,
                        entry: 'outlook.entries.cases' | translate,
                      })
            "
          >
            <span
              class="maximizer-icons icon-add-case text-max-white icon-18"
            ></span>
          </div>
        }
        <!-- Add Note -->
        <div
          kendoTooltip
          [tooltipTemplate]="tooltipTemplate"
          position="bottom"
          [callout]="false"
          id="outlook-entry-ui-entryPage-addNote"
          [routerLink]="
            disabled || !userRights?.notes?.insert ? null : 'add-note'
          "
          class="rounded-full w-8 h-8 flex justify-center items-center"
          [ngClass]="{
            'pointer-events-auto z-50': onboardingStore.inProgress(),
            'bg-neutral-30': disabled || !userRights?.notes?.insert,
            'bg-inserv-100 hover:bg-inserv-120 cursor-pointer':
              !disabled && userRights?.notes?.insert,
          }"
          [title]="
            userRights?.notes?.insert
              ? ('outlook.note.add-title' | translate)
              : ('outlook.rights.defaultMessage'
                | translate
                  : {
                      right: 'outlook.rights.create' | translate,
                      entry: 'outlook.entries.notes' | translate,
                    })
          "
        >
          <!-- Onboarding anchor | Step 1: Guide 2  -->
          <span
            kendoPopoverContainer
            [popover]="createNote2"
            #createNote2Anchor
            #createNote2Container="kendoPopoverContainer"
            showOn="none"
            class="maximizer-icons icon-add-note text-max-white icon-16"
          ></span>
        </div>
        <!-- Add Task -->
        <div
          kendoTooltip
          [tooltipTemplate]="tooltipTemplate"
          position="bottom"
          [callout]="false"
          id="outlook-entry-ui-entryPage-addTask"
          [routerLink]="disabled ? null : 'add-task'"
          class="rounded-full w-8 h-8 flex justify-center items-center"
          [ngClass]="
            disabled
              ? 'bg-neutral-30'
              : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
          "
          [title]="'outlook.task.add-title' | translate"
        >
          <span
            class="maximizer-icons icon-add-task text-max-white icon-16"
          ></span>
        </div>
        @if (lead) {
          <!-- Add Call -->
          <div
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            position="bottom"
            [callout]="false"
            id="outlook-entry-ui-entryPage-addCall"
            [routerLink]="
              disabled || !userRights?.interactionLog?.insert
                ? null
                : 'add-call-log'
            "
            class="rounded-full w-8 h-8 flex justify-center items-center"
            [ngClass]="
              disabled || !userRights?.interactionLog?.insert
                ? 'bg-neutral-30'
                : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
            "
            [title]="
              userRights?.interactionLog?.insert
                ? ('outlook.callLog.add-title' | translate)
                : ('outlook.rights.defaultMessage'
                  | translate
                    : {
                        right: 'outlook.rights.create' | translate,
                        entry:
                          'outlook.callLog.callLogs' | translate | lowercase,
                      })
            "
          >
            <span
              class="maximizer-icons icon-add-call text-max-white icon-16"
            ></span>
          </div>
          <!-- Add Appointment -->
          <div
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            position="bottom"
            [callout]="false"
            id="outlook-entry-ui-entryPage-addAppointment"
            [routerLink]="disabled ? null : 'add-appointment'"
            class="rounded-full w-8 h-8 flex justify-center items-center"
            [ngClass]="
              disabled
                ? 'bg-neutral-30'
                : 'bg-inserv-100 hover:bg-inserv-120 cursor-pointer'
            "
            [title]="'outlook.appointment.add-title' | translate"
          >
            <span
              class="maximizer-icons icon-add-appointment text-max-white icon-16 pt-0.5"
            ></span>
          </div>
        } @else {
          <div
            kendoTooltip
            [tooltipTemplate]="tooltipTemplate"
            position="bottom"
            [callout]="false"
            [tooltipWidth]="100"
            id="outlook-entry-ui-entryPage-moreOptions"
            class="rounded-full w-8 h-8 flex justify-center items-center bg-inserv-100 hover:bg-inserv-120 cursor-pointer"
            [title]="'outlook.buttons.moreOptions' | translate"
          >
            <kendo-dropdownbutton
              #dropdownButton
              [data]="moreActions"
              [buttonClass]="['hover:bg-transparent, hover:shadow-none']"
              fillMode="flat"
              iconClass="fa-icons icon-16 icon-ellipsis text-max-white rounded-full"
            >
              <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                <div
                  kendoTooltip
                  [tooltipTemplate]="tooltipTemplate"
                  position="top"
                  [callout]="false"
                  [showOn]="dataItem.disabled ? 'hover' : 'none'"
                  [title]="
                    'outlook.rights.defaultMessage'
                      | translate
                        : {
                            right: 'outlook.rights.create' | translate,
                            entry: dataItem.title | translate,
                          }
                  "
                  id="outlook-entry-ui-entryPage-moreOptions{{ dataItem.tag }}"
                  class="flex items-center gap-1 w-full"
                  (click)="dataItem.click()"
                >
                  <span [class]="dataItem.icon" class="w-4"></span>
                  <span>{{ dataItem.text }}</span>
                </div>
              </ng-template>
            </kendo-dropdownbutton>
          </div>
        }
      </div>
    </div>
  }

  <!-- Tabs -->
  <kendo-tabstrip
    class="w-full flex-1 flex sticky top-0 z-30 bg-white entry-page"
    tabAlignment="justify"
  >
    <kendo-tabstrip-tab
      [selected]="true"
      title="summary"
      cssClass="flex-1 gap-0"
    >
      <ng-template kendoTabTitle>
        <div id="outlook-entry-ui-entryPage-summary" class="w-full text-center">
          <p class="text-body">
            {{ 'outlook.entryPage.summary' | translate }}
          </p>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <maximizer-entry-summary
          [id]="id"
          [type]="type"
          [lead]="lead"
          [abentry]="abentry"
          [deepLink]="deepLink"
          [oppLink]="oppLink"
        ></maximizer-entry-summary>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="timeline" cssClass="flex-1 gap-0">
      <ng-template kendoTabTitle>
        <div
          id="outlook-entry-ui-entryPage-timeline"
          class="w-full text-center"
        >
          <p class="text-body">
            {{ 'outlook.entryPage.timeline' | translate }}
          </p>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <maximizer-outlook-timeline
          class="h-full"
          [id]="id"
          [type]="type"
          [refreshEvent]="refreshTimelineEmitter"
        ></maximizer-outlook-timeline>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="details" cssClass="flex-1 gap-0">
      <ng-template kendoTabTitle>
        <div id="outlook-entry-ui-entryPage-details" class="w-full text-center">
          <p class="text-body">
            {{ 'outlook.entryPage.details' | translate }}
          </p>
        </div>
      </ng-template>
      <ng-template kendoTabContent>
        <maximizer-entry-details
          [id]="id"
          [type]="type"
        ></maximizer-entry-details>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  [entryId]="entryType"
>
</maximizer-outlook-notification>

<ng-template #tooltipTemplate let-anchor>
  <p class="caption text-neutral-90">
    {{ anchor.nativeElement.getAttribute('data-title') }}
  </p>
</ng-template>

<!-- Onboarding -->
@if (initOnboardingDialog) {
  <maximizer-outlook-celebration
    (action)="handleOnboardingInitAction($event)"
    [step]="'finish'"
  ></maximizer-outlook-celebration>
}

@if (exitOnboarding) {
  <maximizer-outlook-onboarding-exit (action)="exitOnboardingAction($event)">
  </maximizer-outlook-onboarding-exit>
}

<!-- Step 3: Guide 2 -->
<kendo-popover
  #createNote2
  [position]="popoverPosition"
  [width]="lead ? '200px' : '224px'"
>
  <ng-template kendoPopoverBodyTemplate>
    <maximizer-outlook-step-guide
      [guide]="onboardingStore.getGuideForStep(StepGuideId.CreateNote2)"
      (exit)="exitOnboardingGuide()"
      (next)="nextOnboardingStep()"
    ></maximizer-outlook-step-guide>
  </ng-template>
</kendo-popover>
