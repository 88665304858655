import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { DomainModule } from '@maximizer/core/shared/domain';
import { OutlookSharedDomainModule } from '@maximizer/outlook/shared/domain';
import { OutlookService } from './outlook/outlook.service';
import {
  AbEntryService,
  LeadService,
  OutlookSyncService,
  OnboardingService,
} from './services';
import { OnboardingStore, OutlookStore } from './state';

export const INTEGRATION_URL = new InjectionToken<string>(
  'integrationEntrypoint',
);
export const INTEGRATION_SUBSCRIPTION_KEY = new InjectionToken<string>(
  'integrationSubscriptionKey',
);
export const APP_STORE_OAUTH_URL = new InjectionToken<string>(
  'appStoreOAuthUrl',
);
export const APP_STORE_CONFIGURATION_URL = new InjectionToken<string>(
  'appStoreConfigurationUrl',
);
export const INTEGRATION_WEBHOOK_URL = new InjectionToken<string>(
  'integrationWebhookUrl',
);
export const WEBHOOK_EARLY_ACCESS = new InjectionToken<string>(
  'webhookEarlyAccessId',
);

@NgModule({
  imports: [CommonModule, DomainModule, OutlookSharedDomainModule],
})
export class OutlookSharedDataAccessModule {
  static forRoot(
    url: string,
    subscriptionKey: string,
    appStoreOAuthUrl: string,
    appStoreConfigurationUrl: string,
    integrationWebhookUrl: string,
    webhookEarlyAccessId: string,
  ): ModuleWithProviders<OutlookSharedDataAccessModule> {
    return {
      ngModule: OutlookSharedDataAccessModule,
      providers: [
        AbEntryService,
        LeadService,
        OutlookService,
        OutlookSyncService,
        OnboardingStore,
        OutlookStore,
        OnboardingService,
        {
          provide: INTEGRATION_URL,
          useValue: url,
        },
        {
          provide: INTEGRATION_SUBSCRIPTION_KEY,
          useValue: subscriptionKey,
        },
        {
          provide: APP_STORE_OAUTH_URL,
          useValue: appStoreOAuthUrl,
        },
        {
          provide: APP_STORE_CONFIGURATION_URL,
          useValue: appStoreConfigurationUrl,
        },
        {
          provide: INTEGRATION_WEBHOOK_URL,
          useValue: integrationWebhookUrl,
        },
        {
          provide: WEBHOOK_EARLY_ACCESS,
          useValue: webhookEarlyAccessId,
        },
      ],
    };
  }
}
