import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntlModule } from '@progress/kendo-angular-intl';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { UiPipesModule } from '../../pipes/pipes.module';
import { UiCustomGridModule } from '../custom-grid/custom-grid.module';
import { CustomDialogComponent } from './custom-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    IntlModule,
    UiCustomGridModule,
    UiPipesModule,
    ButtonModule,
    DialogsModule,
    BrowserModule,
    BrowserAnimationsModule,
  ],
  declarations: [CustomDialogComponent],
  exports: [CustomDialogComponent],
})
export class UiCustomDialogModule {}
