import { NgModule } from '@angular/core';
import { NgLetModule } from 'ng-let';

import { GoogleModule } from './google/google.module';
import { UiButtonsModule } from './components/buttons/buttons.module';
import { UiContainerModule } from './components/container/container.module';
import { UiCustomGridModule } from './components/custom-grid/custom-grid.module';
import { UiCustomDialogModule } from './components/custom-dialog/custom-dialog.module';
import { UiFieldPickerModule } from './components/field-picker/field-picker.module';
import { UiRichTextEditorModule } from './components/rich-text-editor/rich-text-editor.module';
import { UiKanbanModule } from './components/kanban/kanban.module';
import { UiLoaderModule } from './components/loader/loader.module';
import { UiNotificationModule } from './components/notification/notification.module';
import { UiSorteableListModule } from './components//sorteable-list/sorteable-list.module';
import { UiCardLayoutCustomizationModule } from './components/card-layout-customization/card-layout-customization.module';
import { UiActionPanelModule } from './components/action-panel/action-panel.module';
import { UiCommonDialogsModule } from './components/common-dialogs/common-dialogs.module';
import { UiDirectivesModule } from './directives/directives.module';
import { KendoModule } from './kendo/kendo.module';
import { UiLayoutModule } from './layout/layout.module';
import { UiPipesModule } from './pipes/pipes.module';

@NgModule({
  imports: [
    NgLetModule,
    GoogleModule,
    KendoModule,
    UiButtonsModule,
    UiContainerModule,
    UiCustomDialogModule,
    UiDirectivesModule,
    UiFieldPickerModule,
    UiRichTextEditorModule,
    UiSorteableListModule,
    UiCardLayoutCustomizationModule,
    UiActionPanelModule,
    UiCommonDialogsModule,
    UiKanbanModule,
    UiLayoutModule,
    UiLoaderModule,
    UiNotificationModule,
    UiPipesModule,
  ],
  exports: [
    NgLetModule,
    GoogleModule,
    KendoModule,
    UiButtonsModule,
    UiContainerModule,
    UiCustomDialogModule,
    UiCustomGridModule,
    UiDirectivesModule,
    UiFieldPickerModule,
    UiSorteableListModule,
    UiCardLayoutCustomizationModule,
    UiActionPanelModule,
    UiCommonDialogsModule,
    UiKanbanModule,
    UiLayoutModule,
    UiLoaderModule,
    UiNotificationModule,
    UiPipesModule,
  ],
})
export class UiModule {}
