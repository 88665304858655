import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionPanelComponent } from './action-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { defaultTranslateConfiguration } from '../../config';
import { UiPipesModule } from '../../pipes';
import { UiDirectivesModule } from '../../directives';

@NgModule({
  declarations: [ActionPanelComponent],
  exports: [ActionPanelComponent],
  imports: [
    CommonModule,
    UiPipesModule,
    UiDirectivesModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
})
export class UiActionPanelModule {}
