import { Mapper, Octopus } from '@maximizer/core/shared/domain';
import {
  AbEntryFieldOption,
  OutlookAbEntryDetails,
  OutlookAbEntryForm,
  OutlookAbEntrySearch,
} from '../../../models';
import { capitalize, ifNullOrUndefinedThenEmpty } from '@maximizer/shared/util';

export class AbEntrySearchMapper extends Mapper<
  Octopus.AbEntry,
  OutlookAbEntrySearch
> {
  from(source: Octopus.AbEntry): OutlookAbEntrySearch {
    return {
      key: source.Key,
      parentKey: source.ParentKey?.Value ?? null,
      displayValue: source.DisplayValue,
      type: source.Type,
      name: `${source.FirstName} ${source.LastName}`.trim(),
      companyName: source.CompanyName,
      lastContactDateDisplayValue: source.LastContactDate?.DisplayValue,
      lastContactDateValue: source.LastContactDate?.Value,
      phone1: source.Phone1?.Number,
      phone2: source.Phone2?.Number,
      phone3: source.Phone3?.Number,
    };
  }
}

export class AbEntryDetailsMapper extends Mapper<
  Octopus.AbEntry,
  OutlookAbEntryDetails
> {
  from(source: Octopus.AbEntry): OutlookAbEntryDetails {
    const result: OutlookAbEntryDetails = {
      key: source.Key,
      parentKey: source.ParentKey?.Value ?? null,
      displayValue: source.DisplayValue,
      type: source.Type,
      name: source.FirstName + ' ' + source.LastName,
      firstName: source.FirstName,
      lastName: source.LastName,
      companyName: source.CompanyName,
      position: source.Position,
      website: source.WebSite,
      emails: this.mapEmails(source),
      phones: this.mapPhoneNumbers(source),
      lastContactDateValue: source.LastContactDate?.Value
        ? new Date(source.LastContactDate?.Value)
        : null,
      address: null
    };

    if(source.Address){
      result.address = {
        key: source.Address.Key ?? '',
        address1: source.Address.AddressLine1,
        address2: source.Address.AddressLine2,  
        zipcode: source.Address.ZipCode,
        city: source.Address.City,
        provinceState: source.Address.StateProvince,
        country: source.Address.Country,
      };
    }
    return result;
  }

  mapEmails(source: Octopus.AbEntry) {
    const result: { index: number; type: string; value: string }[] = [];
    let index = 0;
    if (source.Email && source.Email.Address != '') {
      result.push({
        index: ++index,
        type: source.Email.Description.trim(),
        value: source.Email.Address.trim(),
      });
    }
    if (
      source.Email1 &&
      source.Email1.Address != '' &&
      !result.find((o) => o.value == source.Email1.Address?.trim())
    ) {
      result.push({
        index: ++index,
        type: source.Email1.Description.trim(),
        value: source.Email1.Address.trim(),
      });
    }
    if (
      source.Email2 &&
      source.Email2.Address != '' &&
      !result.find((o) => o.value == source.Email2.Address?.trim())
    ) {
      result.push({
        index: ++index,
        type: source.Email2.Description.trim(),
        value: source.Email2.Address.trim(),
      });
    }
    if (
      source.Email3 &&
      source.Email3.Address != '' &&
      !result.find((o) => o.value == source.Email3.Address.trim())
    ) {
      result.push({
        index: ++index,
        type: source.Email3.Description.trim(),
        value: source.Email3.Address.trim(),
      });
    }
    return result;
  }

  private mapPhoneNumbers(source: Octopus.AbEntry) {
    const result: { index: number; type: string; value: string }[] = [];
    let index = 0;
    if (source.Phone && source.Phone.Number.trim() != '') {
      result.push({
        index: ++index,
        type: source.Phone.Description.trim(),
        value: (source.Phone.Number + ' ' + source.Phone.Extension).trim(),
      });
    }
    if (
      source.Phone1 &&
      source.Phone1.Number.trim() != '' &&
      !result.find((o) => o.value == source.Phone1.Number.trim())
    ) {
      result.push({
        index: ++index,
        type: source.Phone1.Description.trim(),
        value: (source.Phone1.Number + ' ' + source.Phone1.Extension).trim(),
      });
    }
    if (
      source.Phone2 &&
      source.Phone2.Number.trim() != '' &&
      !result.find((o) => o.value == source.Phone2.Number.trim())
    ) {
      result.push({
        index: ++index,
        type: source.Phone2.Description.trim(),
        value: (source.Phone2.Number + ' ' + source.Phone2.Extension).trim(),
      });
    }
    if (
      source.Phone3 &&
      source.Phone3.Number.trim() != '' &&
      !result.find((o) => o.value == source.Phone3.Number.trim())
    ) {
      result.push({
        index: ++index,
        type: source.Phone3.Description.trim(),
        value: (source.Phone3.Number + ' ' + source.Phone3.Extension).trim(),
      });
    }
    if (
      source.Phone4 &&
      source.Phone4.Number.trim() != '' &&
      !result.find(
        (o) => o.value == this.mapEmptyToNull(source.Phone4.Number.trim()),
      )
    ) {
      result.push({
        index: ++index,
        type: source.Phone4.Description.trim(),
        value: (source.Phone4.Number + ' ' + source.Phone4.Extension).trim(),
      });
    }
    return result;
  }

  mapEmptyToNull(value?: string) {
    if (!value || value?.trim() == '') return null;
    return value.trim();
  }
}

export class AbEntryEditMapper extends Mapper<
  OutlookAbEntryForm,
  Octopus.AbEntryWriteRequest
> {
  from(source: OutlookAbEntryForm): Octopus.AbEntryWriteRequest {

    const type = capitalize(source.type ?? '');

    const result: Octopus.AbEntryWriteRequest = {
      AbEntry: {
        Data: {
          Key: source.key,
          FirstName: source.firstName,
          LastName: source.lastName,
          CompanyName: source.company,
          Position: source.position,
          WebSite: source.website,
          Type: type,
          Email1: {
            Address: source.email1,
            Description: source.email1Description,
          },
          Email2: {
            Address: ifNullOrUndefinedThenEmpty(source.email2),
            Description: ifNullOrUndefinedThenEmpty(source.email2Description),
          },
          Email3: {
            Address: ifNullOrUndefinedThenEmpty(source.email3),
            Description: ifNullOrUndefinedThenEmpty(source.email3Description),
          },
          Phone1: {
            Number: ifNullOrUndefinedThenEmpty(source.phone1),
            Description: ifNullOrUndefinedThenEmpty(source.phone1Description),
            Extension: '',
          },
          Phone2: {
            Number: ifNullOrUndefinedThenEmpty(source.phone2),
            Description: ifNullOrUndefinedThenEmpty(source.phone2Description),
            Extension: '',
          },
          Phone3: {
            Number: ifNullOrUndefinedThenEmpty(source.phone3),
            Description: ifNullOrUndefinedThenEmpty(source.phone3Description),
            Extension: '',
          },
          Phone4: {
            Number: ifNullOrUndefinedThenEmpty(source.phone4),
            Description: ifNullOrUndefinedThenEmpty(source.phone4Description),
            Extension: '',
          },
        },
      },
    };

    
    if(type !== 'Contact'){
      result.AbEntry.Data.Address = {
        Description: 'Main',
        AddressLine1: ifNullOrUndefinedThenEmpty(source.address1),
        AddressLine2: ifNullOrUndefinedThenEmpty(source.address2),
        City: ifNullOrUndefinedThenEmpty(source.city),
        StateProvince: ifNullOrUndefinedThenEmpty(source.province),
        ZipCode: ifNullOrUndefinedThenEmpty(source.postalCode),
        Country: ifNullOrUndefinedThenEmpty(source.country),
      };       
    }
    
    if (
      type === 'Contact' &&
      ifNullOrUndefinedThenEmpty(source.parentKey) != ''
    ) {
      result.AbEntry.Data.ParentKey = {
        ID: '',
        Value: ifNullOrUndefinedThenEmpty(source.parentKey),
        Number: 0,
        EntityType: 1,
      };
    }
    return result;
  }
}

export class AbEntryFieldOptionMapper extends Octopus.ConfigurationMapper<
  Octopus.AbEntryFieldOptionsResponse,
  AbEntryFieldOption
> {
  from(source: Octopus.AbEntryFieldOptionsResponse): AbEntryFieldOption {
    const task = source.AbEntry?.FieldOptions;

    return {
      phoneDescriptions:
        task?.['Phone/Description']?.map(this.mapKeyAndDisplay) ?? [],
      emailDescriptions:
        task?.['Email/Description']?.map(this.mapKeyAndDisplay) ?? [],
    };
  }
}
