import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  ContextService,
  GlobalStore,
  SessionService,
  UserService,
} from '@maximizer/core/shared/data-access';
import { ListItem, User } from '@maximizer/core/shared/domain';
import {
  FormGroupWrapper,
  FormWrapperBuilder,
  StringValidator,
} from '@maximizer/core/shared/ui';

import { forkJoin, finalize, catchError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NoteService } from '@maximizer/outlook/note/data-access';
import {
  NoteForm,
  NoteModuleConfiguration,
} from '@maximizer/outlook/shared/domain';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';
import {
  StepGuideId,
  OnboardingStore,
  OnboardingStepNumber,
  ExitOnboardingAction,
  OutlookInsightsEvents,
  OnboardingService,
} from '@maximizer/outlook/shared/data-access';
import { PopoverContainerDirective } from '@progress/kendo-angular-tooltip';
import { InsightsService } from '@maximizer/core/shared/insights';

@Component({
  selector: 'maximizer-add-note',
  templateUrl: './add-note.component.html',
})
export class AddNoteComponent implements OnInit, AfterViewInit {
  @HostBinding('class.max-outlook') hostClass = true;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;
  @ViewChild('createNote3Container')
  createNote3Container!: PopoverContainerDirective;
  @ViewChild('createNote3Anchor')
  createNote3Anchor!: Element;

  @Input() type!: 'lead' | 'abentry';

  @Input()
  set id(id: string) {
    if (this.type == 'lead') this._leadKey = decodeURI(id);
    if (this.type == 'abentry') this._abEntryKey = decodeURI(id);
  }
  get id() {
    return this._leadKey ?? this._abEntryKey ?? '';
  }

  private _abEntryKey: string | null = null;
  private _leadKey: string | null = null;

  currentUser?: User;
  form?: FormGroupWrapper<NoteForm>;
  configuration?: NoteModuleConfiguration;
  loading = true;
  canSave = false;
  now = new Date();
  fullFormat = 'dd MMMM yyyy HH:mm:ss';
  exitOnboarding = false;
  StepGuideId = StepGuideId;

  noneValue: ListItem<string> = {
    id: '-1',
    name: this.translate.instant('outlook.forms.none'),
  };

  constructor(
    private readonly noteService: NoteService,
    private readonly session: SessionService,
    private readonly translate: TranslateService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly globalStore: GlobalStore,
    private readonly contextService: ContextService,
    private readonly insightsService: InsightsService,
    private readonly onboardingService: OnboardingService,
    public onboardingStore: OnboardingStore,
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.configuration = {
      options: {
        categories: [this.noneValue],
        types: [this.noneValue],
      },
      users: [],
    };

    this.form?.patch({
      category: this.noneValue.id,
    });

    if (this._leadKey || this._abEntryKey) this.loadData();
  }

  ngAfterViewInit(): void {
    if (this.onboardingStore.inProgress()) {
      this.showOnboardingGuide();
    }
  }

  private setForm(): void {
    this.form = FormWrapperBuilder.group<NoteForm>(
      {
        key: null,
        parentKey: this._abEntryKey ?? this._leadKey,
        dateTime: [this.now, [Validators.required]],
        richText: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
            ]),
          },
        ],
        important: false,
        category: '-1',
        type: '-1',
        creator: [
          null,
          {
            validators: Validators.compose([
              Validators.required,
              Validators.minLength(1),
              StringValidator.NotEmpty(),
            ]),
            updateOn: 'blur',
          },
        ],
        abEntryKey: null,
        leadKey: null,
      },

      this.save,
      () => {
        this.form?.control.setErrors(null);
        if (this.form?.valid) {
          this.canSave = true;
        } else {
          this.canSave = false;
        }
      },
    );
  }

  private loadData(): void {
    forkJoin({
      interactionOptions: this.noteService.getFieldOption(
        this._abEntryKey ?? this._leadKey ?? '',
      ),
      users: this.userService.getActiveUsers(),
      currentUser: this.session.getCurrentUser(),
    })
      .pipe(
        catchError(async () => {
          this.notification.show('load', 'error');
        }),
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe((data) => {
        if (data == null) return;

        const { categories, types } = data.interactionOptions;
        categories.unshift(this.noneValue);
        types.unshift(this.noneValue);

        this.configuration = {
          options: {
            categories: categories ?? [this.noneValue],
            types: types ?? [this.noneValue],
          },
          users: data.users ?? [],
        };

        this.form?.patch({
          creator: data.currentUser.id,
          category: this.noneValue.id,
        });
      });
    if (!this.configuration) return;
  }

  transformToNullWhenNone(input: string | null): string | null {
    if (input == this.noneValue.id) return null;
    return input;
  }

  save(): void {
    if (!this.form) {
      return;
    }
    const category = this.form.value.category;
    this.form.patch({
      category: this.transformToNullWhenNone(this.form.value.category),
      type: 0,
    });

    const validation = this.form.validate();
    if (!validation.valid) return;

    this.loading = true;
    this.noteService
      .create(this.form.value)
      .pipe(
        catchError(async () => {
          this.form?.patch({ category: category });
          this.notification.show('create', 'error');
        }),
        finalize(() => {
          this.loading = false;

          if (this.onboardingStore.inProgress()) {
            this.nextOnboardingStep(3);
          }
        }),
      )
      .subscribe((result) => {
        if (result == null) return;

        this.notification.show('create', 'success');
        this.router.navigate(['../'], { relativeTo: this.route });
      });
  }

  cancel(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  // Onboarding
  showOnboardingGuide(): void {
    this.onboardingStore.setCurrentGuide(StepGuideId.CreateNote3);

    setTimeout(() => {
      this.createNote3Container?.show(this.createNote3Anchor);
    }, 500);
  }

  nextOnboardingStep(currentStep: OnboardingStepNumber): void {
    if (currentStep < OnboardingStepNumber.Step4) {
      this.onboardingStore.setCurrentStep(currentStep + 1);
    }
  }

  exitOnboardingGuide(): void {
    this.createNote3Container?.hide();
    this.onboardingStore.setOnboardingInProgress(false);
    this.exitOnboarding = true;
  }

  exitOnboardingAction(action: ExitOnboardingAction): void {
    if (action === 'cancel') {
      this.exitOnboarding = false;
      this.onboardingStore.setOnboardingInProgress(true);
      this.showOnboardingGuide();
    } else {
      this.exitOnboarding = false;
      this.onboardingStore.setOnboardingInProgress(false);
      this.onboardingService.reset();
      this.onboardingService.trackOnboardingEvent(
        'Exit-Onboarding',
        OutlookInsightsEvents.Onboarding,
        this.contextService.token,
        this.globalStore.session()?.alias,
        StepGuideId.CreateNote3,
      );
    }
  }
}
