import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  DocumentService,
  GlobalStore,
} from '@maximizer/core/shared/data-access';
import { CaseCard, OpportunityCard } from '@maximizer/outlook/shared/domain';
import {
  CreateDocument,
  DocumentType,
  EntryType,
  UserRights,
} from '@maximizer/core/shared/domain';
import { OpportunityService } from '@maximizer/outlook/opportunity/data-access';
import { CaseService } from '@maximizer/outlook/case/data-access';
import {
  OutlookService,
  OutlookStore,
} from '@maximizer/outlook/shared/data-access';
import {
  OutlookNotificationComponent,
  OutlookNotificationType,
} from '@maximizer/outlook/shared/ui';
import { isNullOrEmpty } from '@maximizer/shared/util';
import {
  Observable,
  catchError,
  finalize,
  firstValueFrom,
  forkJoin,
  of,
} from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'maximizer-outlook-related',
  templateUrl: './related.component.html',
  styleUrls: ['./related.component.scss'],
})
export class OutlookRelatedComponent implements OnInit {
  @ViewChild('notification')
  notification?: OutlookNotificationComponent;

  saveEmailLoading = false;
  relatedTitle = this.translate.instant('outlook.main.related.fullTitle');
  currentAbEntryIds: string[] = [];
  opportunities: OpportunityCard[] = [];
  cases: CaseCard[] = [];
  userRights?: UserRights;

  isLoading = {
    spinner: false,
    skeleton: false,
  };

  showMore = {
    opportunities: false,
    cases: false,
  };

  constructor(
    private translate: TranslateService,
    private opportunityService: OpportunityService,
    private caseService: CaseService,
    private outlookService: OutlookService,
    private documentService: DocumentService,
    private router: Router,
    public globalStore: GlobalStore,
    public outlookStore: OutlookStore,
  ) {}

  ngOnInit(): void {
    this.isLoading.skeleton = true;
    this.setUserRights();
  }

  async getSavedEmails(): Promise<void> {
    this.isLoading.skeleton = true;
    try {
      const allEmails = await this.outlookService.getSavedEmails();

      for (const opportunitiesFromOutlook of allEmails.opportunities) {
        const entryFromMaximizerIndex = this.opportunities.findIndex(
          (email) => email.key === opportunitiesFromOutlook,
        );
        if (entryFromMaximizerIndex === -1) continue;
        this.opportunities[entryFromMaximizerIndex].isSaved = true;
      }

      for (const casesFromOutlook of allEmails.cases) {
        const entryFromMaximizerIndex = this.cases.findIndex(
          (email) => email.key === casesFromOutlook,
        );
        if (entryFromMaximizerIndex === -1) continue;
        this.cases[entryFromMaximizerIndex].isSaved = true;
      }
    } catch (error) {
      console.error('Error retrieving saved emails:', error);
    } finally {
      this.isLoading.skeleton = false;
    }
  }

  setUserRights(): void {
    if (!this.globalStore.session()) {
      return;
    }

    this.userRights = this.globalStore.session()?.user.rights;
    this.renderPageTitle();
    this.loadData();
  }

  loadData(): void {
    this.currentAbEntryIds = this.outlookStore.inMaximizerIds();

    const requests: {
      opportunities?: Observable<OpportunityCard[]>;
      cases?: Observable<CaseCard[]>;
    } = {};

    if (this.userRights?.opportunities.read) {
      requests.opportunities = this.opportunityService
        .getOpportunityByContactId(this.currentAbEntryIds)
        .pipe(
          catchError((error) => {
            console.error('Error fetching opportunities:', error);
            return of([]);
          }),
        );
    }

    if (this.userRights?.customerService.read) {
      requests.cases = this.caseService
        .getCaseByContactId(this.currentAbEntryIds)
        .pipe(
          catchError((error) => {
            console.error('Error fetching cases:', error);
            return of([]);
          }),
        );
    }

    forkJoin(requests)
      .pipe(
        finalize(() => {
          this.isLoading.skeleton = false;
        }),
      )
      .subscribe((result) => {
        if (result.opportunities) {
          this.opportunities = result.opportunities;
        }

        if (result.cases) {
          this.cases = result.cases;
        }

        if (!result.cases?.length && !result.opportunities?.length) {
          this.router.navigate(['/home']);
          return;
        }

        this.getSavedEmails();
      });
  }

  renderPageTitle(): void {
    if (!this.userRights?.opportunities.read) {
      this.relatedTitle = this.translate.instant(
        'outlook.main.related.casesTitle',
      );
    } else if (!this.userRights?.customerService.read) {
      this.relatedTitle = this.translate.instant(
        'outlook.main.related.opportunitiesTitle',
      );
    }
  }

  async saveEmail(key: string, type: EntryType): Promise<void> {
    this.isLoading.spinner = true;
    try {
      const emlFile = await this.outlookService.getReadEmlFile();
      if (!emlFile) throw new Error('Unable to get EML file');

      const documentId = await this.createEmailInMaximizer(emlFile, key);
      if (!documentId) {
        this.showNotificationMessage(
          'error',
          'outlook.email-notification.fail-entry',
        );
        return;
      }

      if (type === 'opportunity') {
        this.showNotificationMessage(
          'success',
          'outlook.email-notification.successOpportunity',
        );
      }

      if (type === 'case') {
        this.showNotificationMessage(
          'success',
          'outlook.email-notification.successCase',
        );
      }

      this.showAsSavedEmail(key, type);
      await this.outlookService.saveOpportunityOrCaseInOutlook(key, type);
    } catch (error) {
      console.error('Error during saveEmail:', error);
      this.showNotificationMessage(
        'error',
        'outlook.email-notification.fail-entry',
      );
    } finally {
      this.isLoading.spinner = false;
    }
  }

  private async createEmailInMaximizer(
    emlFile: File,
    parentKey: string,
  ): Promise<string | null> {
    const attachment$ = this.documentService.createAttachment(
      emlFile,
      'application/octet-stream',
    );
    const attachmentId = await firstValueFrom(attachment$);

    const item = this.outlookService.mailboxItem;
    if (!item) return null;

    const document: CreateDocument = {
      name: isNullOrEmpty(item.subject) ? 'Untitled email' : item.subject,
      binaryDataId: attachmentId ?? '',
      dateTime: item.dateTimeCreated,
      extension: '.eml',
      type: DocumentType.Emails,
      size: emlFile.size,
      parentKey,
    };

    const document$ = this.documentService.createDocument(document);
    return firstValueFrom(document$);
  }

  showAsSavedEmail(key: string, type: EntryType): void {
    if (type === 'opportunity') {
      const opportunity = this.opportunities.find((item) => item.key === key);
      if (opportunity) {
        opportunity.isSaved = true;
      }
    }

    if (type === 'case') {
      const caseItem = this.cases.find((item) => item.key === key);
      if (caseItem) {
        caseItem.isSaved = true;
      }
    }
  }

  showNotificationMessage(
    type: OutlookNotificationType,
    message: string,
  ): void {
    const translatedMessage = this.translate.instant(message);
    this.notification?.showMessage(type, translatedMessage);
  }
}
