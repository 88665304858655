export class Action {
  static readonly AUTHENTICATE = '/json/Authenticate';
  static readonly READ = '/json/Read';
  static readonly READ_CONFIGURATION = '/json/ConfigurationRead';
  static readonly READ_USER = '/json/UserRead';
  static readonly USER_UPDATE = '/json/UserUpdate';
  static readonly USER_CREATE = '/json/UserCreate';
  static readonly CREATE = '/json/Create';
  static readonly UPDATE = '/json/Update';
  static readonly DELETE = '/json/Delete';
  static readonly SESSION_INFO = '/json/GetSessionInfo';
  static readonly STATISTICS = '/json/GetStatistics';
  static readonly MAINTENANCE = '/json/Maintenance';
  static readonly VALIDATE = '/json/Validate';
  static readonly DuplicateCheck = '/json/DuplicateCheck';
  static readonly ATTACHMENTCREATE = '/json/AttachmentCreate';
  static readonly SYSTEM_INFO = '/json/GetSystemInfo';
}

export enum ActionType {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update',
}
