<div class="p-4 h-full bg-neutral-5">
  <maximizer-summary-task
    [type]="type"
    [id]="id"
    [deepLink]="deepLink"
    (loadErrorEvent)="showNotification($event)"
    class="flex flex-col w-full"
  ></maximizer-summary-task>

  <maximizer-summary-note
    [id]="id"
    [deepLink]="deepLink"
    [lead]="lead"
    [abentry]="abentry"
    class="flex flex-col w-full"
    (loadErrorEvent)="showNotification($event)"
  ></maximizer-summary-note>

  <maximizer-summary-appointment
    [id]="id"
    [type]="type"
    [deepLink]="deepLink"
    (loadErrorEvent)="showNotification($event)"
    class="flex flex-col w-full"
  ></maximizer-summary-appointment>

  @if (userRights?.opportunities?.read) {
    <maximizer-summary-opportunity
      *ngIf="type === 'abentry'"
      [id]="id"
      [deepLink]="oppLink"
      (loadErrorEvent)="showNotification($event)"
      class="flex flex-col w-full"
    ></maximizer-summary-opportunity>
  }
</div>

<maximizer-outlook-notification
  #notification
  [closable]="true"
  entryId="summary"
>
</maximizer-outlook-notification>
