import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Column,
  CustomAction,
  CustomGrid,
} from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';

@Injectable()
export class CustomActionService {
  constructor(private readonly http: HttpClient) {}
  getCustomData(
    columns: Column[],
    url: string,
    dataField?: string,
    token?: string,
  ): Observable<CustomGrid[]> {
    const apiUrl = this.constructEncodedUrl(url);
    const headers = token
      ? new HttpHeaders({
          Authorization: token,
          'Content-Type': 'application/json',
        })
      : new HttpHeaders({
          'Content-Type': 'application/json',
        });

    return this.http.get<CustomGrid | CustomGrid[]>(apiUrl, { headers }).pipe(
      map((response) => {
        if (response) {
          const mapper = new CustomAction.CustomGridMapper(columns);
          const responseData = dataField
            ? (response as CustomGrid)[dataField]
            : response;
          if (!Array.isArray(responseData)) {
            return mapper.from([responseData as CustomGrid]);
          } else {
            return mapper.from(responseData as CustomGrid[]);
          }
        }
        return [];
      }),
    );
  }

  private constructEncodedUrl(serviceUrl: string): string {
    const url = new URL(serviceUrl);
    const baseUrl = url.origin + url.pathname;
    const searchParams = new URLSearchParams(url.search);
    const encodedParams = new URLSearchParams();
    searchParams.forEach((value, key) => {
      if (value) {
        encodedParams.append(key, encodeURIComponent(value));
      }
    });

    const encodedUrl = baseUrl + '?' + encodedParams.toString();

    return encodedUrl;
  }
}
