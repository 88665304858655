export enum EntityType {
  AbEntry = 1,
  Opportunity = 2,
  Campaign = 3,
  User = 4,
  Case = 5,
  Appointment = 10,
  Task = 15,
  Lead = 19,
  InteractionLog = 20,
  InteractionLogDefault = InteractionLog,
  ImportTemplate = 17,
  Address = 63,
  Export = 69,
  SalesTeam = 1009,
  Interaction = 10003,
  EmailDocument = 10005,
  EmailDocumentDefault = EmailDocument,
}

export type EntityCode = keyof typeof EntityType;

export const AppointmentEntityCode: EntityCode = 'Appointment';
export const AbEntryEntityCode: EntityCode = 'AbEntry';
export const CaseEntityCode: EntityCode = 'Case';
export const OpportunityEntityCode: EntityCode = 'Opportunity';
export const TaskEntityCode: EntityCode = 'Task';
export const AddressEntityCode: EntityCode = 'Address';
