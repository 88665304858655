import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { map, Observable } from 'rxjs';
import { Octopus, DuplicateCheck } from '@maximizer/core/shared/domain';

@Injectable()
export class DuplicateCheckService {
  constructor(
    private readonly http: HttpClient,
    private readonly context: ContextService,
  ) {}

  private getRequest(
    entityType: Octopus.EntityType,
  ): Octopus.DuplicateCheckReadRequest {
    const fields: Octopus.Scope<Octopus.DuplicateCheck> = {
      Key: 1,
      EntityType: 1,
      Rules: [
        {
          Name: 1,
        },
      ],
      Enabled: 1,
      AllowPartialMatch: 1,
      UserCanDuplicate: 1,
      SecStatus: {
        CanRead: 1,
        CanCreate: 1,
        CanUpdate: 1,
        CanDelete: 1,
      },
    };

    return {
      DuplicateCheck: {
        Scope: {
          Fields: fields,
        },
        Criteria: {
          SearchQuery: {
            EntityType: {
              $EQ: entityType,
            },
          },
        },
      },
      Globalization: Octopus.DefaultGlobalization,
    };
  }

  read(entityType: Octopus.EntityType): Observable<DuplicateCheck> {
    const request = this.getRequest(entityType);

    return this.http
      .post<Octopus.DuplicateCheckReadResponse>(
        `${this.context.api}${Octopus.Action.READ}`,
        request,
        { headers: this.getRequestHeaders() },
      )
      .pipe(
        map((result) => {
          if (result.Code === 0 && result.DuplicateCheck?.Data.length) {
            const mapper = new Octopus.DuplicateCheckMapper();
            return mapper.from(result.DuplicateCheck?.Data[0]);
          }
          return { isEnabled: false };
        }),
      );
  }

  private getRequestHeaders(): { [header: string]: string } {
    return {
      'max-insights-entity': 'DuplicateCheck',
    };
  }
}
