import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Octopus, User } from '@maximizer/core/shared/domain';
import { map, Observable } from 'rxjs';
import { ContextService } from './context.service';

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    private context: ContextService,
  ) {}

  getActiveUsers(): Observable<User[]> {
    const query: Octopus.Query<Octopus.User> = {
      Enabled: {
        $EQ: true,
      },
    };

    return this.getUsers(query);
  }

  getAdministrators(): Observable<User[]> {
    return this.getByRole('Administrator');
  }

  getSalesManagers(): Observable<User[]> {
    return this.getByRole('SalesManager');
  }

  getSalesRepresentatives(): Observable<User[]> {
    return this.getByRole('SalesRepresentative');
  }

  getByRole(role: keyof Octopus.UserRoles) {
    const query: Octopus.Query<Octopus.User> = {
      Enabled: {
        $EQ: true,
      },
      Roles: {
        $EQ: {
          [role]: true,
        },
      },
    };

    return this.getUsers(query);
  }

  getByKey(key: string): Observable<User | null> {
    const query: Octopus.Query<Octopus.User> = {
      Key: {
        $EQ: { Value: key },
      },
    };

    return this.getUsers(query).pipe(
      map((users) => (users.length ? users[0] : null)),
    );
  }

  getUsersWithAnyAccess(): Observable<User[]> {
    return this.getUsers().pipe(
      map((users) =>
        users.filter((user) => user.webAccess || user.serviceAccess),
      ),
    );
  }

  getUsers(query?: Octopus.Query<Octopus.User>): Observable<User[]> {
    const request = this.getRequest(query);

    return this.http
      .post<Octopus.UserReadResponse>(
        `${this.context.api}${Octopus.Action.READ_USER}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.UserMapper();
            return result.User.Data.map((user) => mapper.from(user));
          }
          return [];
        }),
      );
  }

  createUser(user: Partial<Octopus.User>): Observable<boolean> {
    const request: Octopus.UserUpdateRequest = this.getUpdateRequest(user);

    return this.http
      .post<Octopus.UserUpdateResponse>(
        `${this.context.api}${Octopus.Action.USER_CREATE}`,
        request,
      )
      .pipe(
        map((result) => result.Code === Octopus.ResponseStatusCode.Successful),
      );
  }

  updateUser(user: Partial<Octopus.User>): Observable<User | undefined> {
    const request = this.getUpdateRequest(user);
    request.Configuration = Octopus.UserWriteDriver;
    return this.http
      .post<Octopus.UserUpdateResponse>(
        `${this.context.api}${Octopus.Action.USER_UPDATE}`,
        request,
      )
      .pipe(
        map((result) => {
          if (result.Code === Octopus.ResponseStatusCode.Successful) {
            const mapper = new Octopus.UserMapper();
            return mapper.from(result.User.Data);
          }
          return undefined;
        }),
      );
  }

  private getRequest(
    query?: Octopus.Query<Octopus.User>,
  ): Octopus.UserReadRequest {
    const permissions: Octopus.Scope<Octopus.Permissions> = {
      Read: 1,
      Create: 1,
      Update: 1,
      Delete: 1,
    };

    const searchQuery: Octopus.LogicalQuery<Octopus.User> = {
      $AND: [
        {
          Key: {
            $NIN: [{ UID: 'EMAILUSER' }, { UID: 'WEBUSER' }],
          },
        },
      ],
    };
    if (query) {
      searchQuery.$AND?.push(query);
    }
    return {
      User: {
        Scope: {
          Fields: {
            Key: {
              Value: 1,
              Uid: 1,
            },
            Phone1: {
              Number: 1,
              Extension: 1,
            },
            Phone2: {
              Number: 1,
              Extension: 1,
            },
            Phone3: {
              Number: 1,
              Extension: 1,
            },
            Phone4: {
              Number: 1,
              Extension: 1,
            },
            Email1: {
              Default: 1,
              Address: 1,
              DisplayValue: 1,
              Description: 1,
            },
            Email2: {
              Default: 1,
              Address: 1,
              DisplayValue: 1,
              Description: 1,
            },
            Email3: {
              Default: 1,
              Address: 1,
              DisplayValue: 1,
              Description: 1,
            },
            DisplayName: 1,
            MrMs: 1,
            Initial: 1,
            FirstName: 1,
            LastName: 1,
            CompanyName: 1,
            Position: 1,
            Department: 1,
            Division: 1,
            WebSite: 1,
            Address: {
              AddressLine1: 1,
              AddressLine2: 1,
              City: 1,
              StateProvince: 1,
              ZipCode: 1,
              Country: 1,
            },
            DisplayValue: 1,
            Roles: {
              Administrator: 1,
              SalesManager: 1,
              SalesRepresentative: 1,
              CustomerServiceManager: 1,
              CustomerServiceRepresentative: 1,
            },
            Permissions: {
              AccountSetup: permissions,
              Quota: permissions,
              Opportunity: permissions,
              UserDefinedFields: permissions,
              InteractionLog: permissions,
              Notes: permissions,
              Documents: permissions,
              CustomerService: permissions,
              Lead: permissions,
              AddressBook: permissions,
            },
            Privileges: {
              Export: 1,
              Import: 1,
              GlobalEdit: 1,
              PrivateEntries: 1,
              ModifyKeyFields: 1,
              ModifySystemTables: 1,
            },
            Enabled: 1,
            Disabled: 1,
          },
        },
        Criteria: {
          SearchQuery: searchQuery,
        },
        OrderBy: {
          Fields: [
            {
              FirstName: 'ASC',
            },
          ],
        },
      },
      Configuration: Octopus.UserReadDriver,
    };
  }

  private getUpdateRequest(
    data: Partial<Octopus.User>,
  ): Octopus.UserUpdateRequest {
    return {
      User: {
        Data: data,
      },
    };
  }
}
