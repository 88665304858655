/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[maximizerCustomDialog]',
})
export class CustomDialogDirective implements OnInit {
  @Output()
  openDialog = new EventEmitter();

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit() {
    const inputElement = this.el.nativeElement.querySelector('input');

    this.renderer.listen(inputElement, 'click', () => {
      this.openDialog.emit();
    });
  }
}
