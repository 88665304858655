export const DefaultJourneyValue = 'action';
export const DefaultActionValue = 'click';

export interface SystemEventData {
  event?: string;
  metadata?: Record<string, string>;
}
export interface TrackEventData extends SystemEventData {
  [key: string]: unknown;
  module?: string;
  location?: string;
  journey?: string;
  action?: string;
}
