/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[overflowTooltip]',
})
export class OverflowTooltipDirective implements AfterViewInit {
  constructor(
    private readonly element: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  ngAfterViewInit() {
    if (!this.isTextOverflowing()) {
      this.renderer.removeAttribute(this.element.nativeElement, 'title');
    }
  }

  private isTextOverflowing(): boolean {
    const element = this.element.nativeElement as HTMLElement;
    return element.scrollWidth > element.clientWidth;
  }
}
