import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContextService,
  OAuth2Service,
  SessionService,
  SessionStorageService,
  SystemService,
} from '@maximizer/core/shared/data-access';
import {
  GlobalServicesLoginDetails,
  OAuth2Token,
} from '@maximizer/core/shared/domain';
import { InsightsService } from '@maximizer/core/shared/insights';
import {
  OnboardingStore,
  OutlookStore,
} from '@maximizer/outlook/shared/data-access';
import { OutlookNotificationComponent } from '@maximizer/outlook/shared/ui';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { catchError, finalize, map, of } from 'rxjs';

@Component({
  selector: 'maximizer-outlook-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class OutlookLoginComponent implements OnInit, AfterViewInit {
  @HostBinding('class.max-outlook') hostClass = true;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;

  url = window.location.origin;
  appVersion?: string;
  isLoggedIn = false;

  constructor(
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly session: SessionService,
    private readonly sessionStorage: SessionStorageService,
    private readonly insights: InsightsService,
    private readonly oauthService: OAuth2Service,
    private readonly activatedRoute: ActivatedRoute,
    private readonly systemService: SystemService,
    private readonly outlookStore: OutlookStore,
    public onboardingStore: OnboardingStore,
  ) {}

  ngOnInit(): void {
    this.verifyIfAlreadyLoggedIn();
    this.appVersion = this.contextService.version;
    this.sessionStorage.set('url', this.url);

    this.insights.trackPageView({
      name: 'outlook-version',
      pageType: Office.context.platform?.toString(),
    });
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams.subscribe((p) => {
      if (this.isLoggedIn) return;
      const error = p['error'];
      if (error === 'auth') {
        this.notification.showMessage(
          'error',
          'outlook.auth.login.fail-auth-notification',
          true,
        );
      }
    });
  }

  async openDialog() {
    try {
      if (Office.context.ui) {
        this.trackEvent('login');
        this.openOutlookLogin();
      } else {
        this.openStandaloneLogin();
      }
    } catch (error) {
      console.error(error);
    }
  }

  processMessage(arg: { message: string; origin: string | undefined }) {
    const messageFromDialog = JSON.parse(arg.message);
    const messageObjFromDialog = messageFromDialog as {
      messageType: string;
      auth: OAuth2Token;
      db: GlobalServicesLoginDetails;
      email: string;
    };

    if (messageObjFromDialog.messageType === 'dialogClosed') {
      this.notification.showMessage(
        'error',
        'outlook.auth.login.fail-notification',
        true,
      );
      return;
    }
    if (messageObjFromDialog.messageType === 'success') {
      this.processSuccessful(
        messageObjFromDialog.db,
        messageObjFromDialog.auth,
      );
    }
  }

  openLink(type: string) {
    let link = '';
    switch (type) {
      case 'signup':
        link = 'https://www.maximizer.com/book-a-demo/';
        break;
      case 'terms':
        link = 'https://www.maximizer.com/about-us/terms-of-use/';
        break;
      case 'policy':
        link = 'https://www.maximizer.com/about-us/privacy-policy/';
        break;
      default:
        break;
    }

    this.trackEvent(type);
    window.open(link, '_blank', 'noopener,noreferrer');
  }

  private trackEvent(
    event: string,
    click = true,
    customProperties?: { alias: string; userId: string },
  ) {
    let eventName = event;
    if (click) {
      eventName = 'Click-' + event;
    }
    this.insights.trackEvent(
      { name: eventName },
      SeverityLevel.Information,
      customProperties,
    );
    this.insights.flush();
  }

  private verifyIfAlreadyLoggedIn() {
    const officeEmail =
      Office.context.mailbox.userProfile?.emailAddress.toLocaleLowerCase() ??
      'mx_default';
    this.oauthService.storagePartitionKey =
      Office.context.partitionKey ?? officeEmail;
    this.oauthService.encryptPassword = officeEmail;
    if (this.oauthService.populateFromStorage()) {
      this.isLoggedIn = true;
      this.router.navigate(['/home']);
    } else {
      this.onboardingStore.setOnboardingInProgress(false);
    }
  }

  private openOutlookLogin() {
    Office.context.ui?.displayDialogAsync(
      this.url + '/login-email',
      { height: 60, width: 30 },
      (asyncResult) => {
        const dialog = asyncResult.value;

        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          (arg) => {
            dialog.close();
            this.processMessage(
              arg as { message: string; origin: string | undefined },
            );
          },
        );
      },
    );
  }

  private openStandaloneLogin() {
    const widthPercent = 40;
    const heightPercent = 60;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const width = screenWidth * (widthPercent / 100);
    const height = screenHeight * (heightPercent / 100);

    const left = (screenWidth - width) / 2;
    const top = (screenHeight - height) / 2;

    const centralizedWindow = `width=${width},height=${height},left=${left},top=${top}`;

    const dialogWindow = window.open(
      '/login-email',
      'authDialog',
      centralizedWindow,
    );

    window.addEventListener(
      'message',
      (event) => {
        dialogWindow?.close();
        if (
          event.origin === window.location.origin &&
          event.data.type === 'outlook'
        ) {
          this.processMessage({
            message: event.data.auth,
            origin: event.origin,
          });
        }
      },
      { once: true },
    );
  }

  private processSuccessful(
    loginDetails: GlobalServicesLoginDetails,
    oauth2Token: OAuth2Token,
  ) {
    const officeEmail =
      Office.context.mailbox?.userProfile?.emailAddress?.toLocaleLowerCase() ??
      'mx_default';
    this.oauthService.oAuth2Token = oauth2Token;
    this.oauthService.loginDetails = loginDetails;
    this.oauthService.populateContextOAuthData();

    this.session
      .getCurrentUser()
      .pipe(
        catchError((error) => {
          this.insights.trackEvent(
            { name: 'Login-fail' },
            SeverityLevel.Error,
            { error },
          );
          return of(null);
        }),
      )
      .subscribe((data) => {
        let mxUserId = '';
        let mxUserEmail = '';
        if (!data) {
          mxUserId = 'mx_default';
          mxUserEmail = 'mx_default';
        } else {
          mxUserId = data.id ?? 'mx_default';
          mxUserEmail =
            data.email1.email ??
            data.email2.email ??
            data.email3.email ??
            'mx_default';
        }

        this.oauthService.storagePartitionKey =
          Office.context?.partitionKey ?? officeEmail;
        this.oauthService.encryptPassword = officeEmail;
        this.oauthService.populateContextOAuthData();
        this.oauthService.setStorageMxUserId(mxUserId);
        this.oauthService.setStorageMxUserEmail(mxUserEmail);
        this.oauthService.setStorageLoginDetails(loginDetails);
        this.oauthService.setStorageToken(oauth2Token);
        this.trackEvent('Login-success', false, {
          alias: loginDetails.tenant.alias,
          userId: mxUserId,
        });

        this.checkSystemInfoForSyncSupport();
      });
  }

  checkSystemInfoForSyncSupport(): void {
    this.systemService
      .getInfo()
      .pipe(
        map((systemInfo) =>
          systemInfo
            ? this.systemService.validateDbVersionForNewSync(systemInfo)
            : false,
        ),
        catchError((error) => {
          console.error('Error fetching system info:', error);
          this.insights.trackException(
            { error },
            { message: `Failed to get system info: ${error.message}` },
          );
          return of(false);
        }),
        finalize(() => {
          this.router.navigate(['/home']);
        }),
      )
      .subscribe((valid) => {
        this.outlookStore.updateVersion(valid);
      });
  }
}
