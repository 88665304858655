import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRights } from '@maximizer/core/shared/domain';
import { OpportunityCard } from '@maximizer/outlook/shared/domain';

@Component({
  selector: 'maximizer-outlook-opportunity-card',
  templateUrl: './opportunity-card.component.html',
})
export class OpportunityCardComponent {
  @Output() save = new EventEmitter<string>();

  @Input() userRights?: UserRights;
  @Input() opportunity: OpportunityCard = {
    key: '',
    objective: '',
    parentKey: '',
    abEntry: {
      key: null,
      companyName: '',
    },
    currentStage: null,
    closeDate: null,
    owner: {
      value: '',
      display: '',
    },
    isSaved: false,
  };
}
