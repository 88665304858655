export enum EntryType {
  AddressBook = 'addressBook',
  AbEntry = 'abentry',
  Case = 'case',
  CustomerService = 'customerService',
  Campaign = 'campaign',
  Document = 'document',
  Interaction = 'interaction',
  Lead = 'lead',
  Note = 'note',
  Opportunity = 'opportunity',
  SalesProcess = 'salesProcess',
  Task = 'task',
  Team = 'team',
  User = 'user',
}
