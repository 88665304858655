<maximizer-outlook-menu
  [id]="id"
  [pageType]="type"
  [pageTitle]="'outlook.note.add-title' | translate"
  (exit)="exitOnboardingGuide()"
></maximizer-outlook-menu>

@if (loading) {
  <maximizer-loader *ngIf="loading" type="legacy"></maximizer-loader>
}

<div class="flex flex-col h-full scrollbar">
  @if (form) {
    <form
      class="flex flex-1 grow flex-col p-4 text-xs space-y-2"
      [formGroup]="form.group"
    >
      <kendo-formfield>
        <kendo-label [for]="richText" [text]="'outlook.note.note' | translate"
          >*
        </kendo-label>
        <kendo-textarea
          [rows]="6"
          resizable="vertical"
          formControlName="richText"
          required
          #richText
        ></kendo-textarea>
        <kendo-formerror>{{
          'outlook.forms.requiredField' | translate
        }}</kendo-formerror>
      </kendo-formfield>
      <!-- Onboarding anchor | Step 3: Guide 3  -->
      <span
        kendoPopoverContainer
        [popover]="createNote3"
        #createNote3Anchor
        #createNote3Container="kendoPopoverContainer"
        showOn="none"
      ></span>
      <kendo-formfield class="flex">
        <input
          class="order-first"
          kendoCheckBox
          type="checkbox"
          formControlName="important"
          #important
        />
        <label
          [for]="important"
          class="ml-1 text-sm order-last k-checkbox-label"
        >
          <span
            class="ml-1 mr-1 fa-icons icon-solid icon-16 icon-exclamation"
          ></span>
          {{ 'outlook.note.important' | translate }}
        </label>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label
          [for]="dateTime"
          [text]="'outlook.note.datetime' | translate"
          >*
        </kendo-label>
        <kendo-datetimepicker
          #dateTime
          [format]="fullFormat"
          formControlName="dateTime"
          required
          calendarType="classic"
        >
        </kendo-datetimepicker>
        <kendo-formerror>{{
          'outlook.forms.requiredField' | translate
        }}</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label [for]="creator" [text]="'outlook.note.creator' | translate"
          >*
        </kendo-label>

        <kendo-dropdownlist
          [data]="configuration?.users"
          textField="name"
          valueField="id"
          fillMode="solid"
          [valuePrimitive]="true"
          formControlName="creator"
          #creator
          required
        >
        </kendo-dropdownlist>
      </kendo-formfield>

      <kendo-formfield *ngIf="type === 'abentry'">
        <kendo-label [text]="'outlook.note.category' | translate"></kendo-label>
        <kendo-dropdownlist
          [data]="configuration?.options?.categories"
          textField="name"
          valueField="id"
          fillMode="solid"
          formControlName="category"
          [valuePrimitive]="true"
        >
        </kendo-dropdownlist>
      </kendo-formfield>
    </form>
  }

  <div
    class="bg-neutral-5 z-40 mt-auto flex space-x-2 p-2 w-full bottom-0 sticky"
  >
    <button
      kendoButton
      id="outlook-note-ui-addNote-save"
      class="uppercase rounded ml-auto"
      themeColor="primary"
      [disabled]="!canSave || loading"
      (click)="save()"
    >
      {{ 'outlook.buttons.save' | translate }}
    </button>
    <button
      kendoButton
      id="outlook-note-ui-addNote-cancel"
      rounded="large"
      class="uppercase rounded"
      themeColor="primary"
      (click)="cancel()"
      [disabled]="loading"
      fillMode="flat"
    >
      {{ 'outlook.buttons.cancel' | translate }}
    </button>
  </div>
</div>

<maximizer-outlook-notification
  #notification
  icon="circle-exclamation"
  [closable]="true"
  entryId="note"
>
</maximizer-outlook-notification>

<!-- Onboarding -->
@if (exitOnboarding) {
  <maximizer-outlook-onboarding-exit (action)="exitOnboardingAction($event)">
  </maximizer-outlook-onboarding-exit>
}

<!-- Step 3: Guide 3 -->
<kendo-popover #createNote3 position="bottom" width="224px" [offset]="-40">
  <ng-template kendoPopoverBodyTemplate>
    <maximizer-outlook-step-guide
      [guide]="onboardingStore.getGuideForStep(StepGuideId.CreateNote3)"
      (exit)="exitOnboardingGuide()"
    ></maximizer-outlook-step-guide>
  </ng-template>
</kendo-popover>
