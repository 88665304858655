import { Mapper } from '../../../mappers';
import { DuplicateCheck as OctopusDuplicateCheck } from '../models';
import { DuplicateCheck } from '../../../models';

export class DuplicateCheckMapper extends Mapper<
  OctopusDuplicateCheck,
  DuplicateCheck
> {
  from(source: OctopusDuplicateCheck): DuplicateCheck {
    return {
      key: source.Key,
      isEnabled: source.Enabled,
      userCanDuplicate: source.UserCanDuplicate,
      rules: source.Rules?.map((rule) => rule.Name),
    } as DuplicateCheck;
  }
}
