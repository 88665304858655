import { Injectable, OnDestroy } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import {
  FieldOptionsService,
  SalesTeamService,
  SessionService,
} from '@maximizer/core/shared/data-access';
import {
  Entity,
  Field,
  ListItem,
  Octopus,
  SelectorField,
  User,
} from '@maximizer/core/shared/domain';
import {
  Observable,
  Subscription,
  distinctUntilChanged,
  map,
  of,
  tap,
} from 'rxjs';
import { ResourceOrTranslatePipe } from '../../pipes/resource-or-translate.pipe';
import { LayoutFormControl } from '../classes/layout-form-control';

@Injectable()
export class ListHandler implements OnDestroy {
  subscriptions: Subscription[] = [];
  currentUser?: User;

  readonly excludedOptions: Record<string, unknown[]> = {
    [Octopus.OpportunityFields.Status]: [Octopus.OpportunityStatus.Updated],
  };

  readonly keyOverrides: Record<string, string> = {
    [Octopus.OpportunityFields.ActualRevenueCurrency]:
      Octopus.OpportunityFields.ForecastRevenueCurrency,
  };

  readonly customPlaceholders: Record<
    string,
    { resource: string; translation: string }
  > = {
    [Octopus.OpportunityFields.SalesProcessSetup]: {
      resource: 'JSS_GenTextSelectPorcess',
      translation: 'pipeline.select',
    },
  };

  readonly withData: Record<string, Entity> = {
    [Octopus.EntityType[Octopus.EntityType.Opportunity]]: {
      [Octopus.OpportunityFields.Reason]: () => {
        const status = this.form.control.get(Octopus.OpportunityFields.Status);
        return {
          [Octopus.OpportunityFields.Status]: status?.value,
        };
      },
    },
  };

  readonly editableList: Record<string, Entity> = {
    [Octopus.EntityType[Octopus.EntityType.Opportunity]]:
      Octopus.OpportunityEditableListFields,
    [Octopus.EntityType[Octopus.EntityType.AbEntry]]:
      Octopus.AbEntryFieldsEditableListFields,
    [Octopus.EntityType[Octopus.EntityType.Address]]:
      Octopus.AddressFieldsEditableListFields,
  };

  constructor(
    private form: FormGroupDirective,
    private options: FieldOptionsService,
    private salesTeam: SalesTeamService,
    private session: SessionService,
    private resource: ResourceOrTranslatePipe,
  ) {
    this.subscriptions.push(
      this.session
        .getCurrentUser()
        .pipe(
          tap((user) => {
            this.currentUser = user;
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  handleChanges(control: LayoutFormControl): void {
    if (control.field.metadata?.entity === Octopus.OpportunityEntityCode) {
      if (control.field.id === Octopus.OpportunityFields.SalesTeam) {
        this.handleSalesTeamChanges(control);
      }
    }
  }

  checkOptions(control: LayoutFormControl): void {
    if (control.field.type === 'selector' && !control.hidden) {
      const selector = control.field as SelectorField<unknown>;

      if (selector.selection === 'single') {
        const hasValue = control.value !== undefined && control.value !== null;
        const hasOptionForValue = hasValue
          ? selector.options?.find((option) => option.id === control.value)
          : false;

        if (hasValue && !hasOptionForValue) {
          this.subscriptions.push(
            this.getOptions(selector).subscribe((options) => {
              selector.options = options;
            }),
          );
        }
      }
    }
  }

  getOptions(field: SelectorField<unknown>): Observable<ListItem<unknown>[]> {
    const entity = field.metadata?.entity;

    switch (field.id) {
      case Octopus.AbEntryFields.Starred:
      case Octopus.AbEntryFields.ReportsTo:
      case Octopus.OpportunityFields.Leader:
      case Octopus.OpportunityFields.LostToKey:
        return of(field.options);
      default:
        if (entity) {
          const data = this.getData(entity, field);
          let id = this.keyOverrides[field.id] ?? field.id;
          if (field.metadata?.tree) {
            id = [...field.metadata.tree, id].join('/');
          }
          return this.options
            .getList(entity, id, data)
            .pipe(map((options) => this.mapOptions(entity, options, field)));
        }
        return of(field.options ?? []);
    }
  }

  private mapOptions(
    entity: string,
    options: ListItem<unknown>[],
    field: SelectorField<unknown>,
  ): ListItem<unknown>[] {
    const excludedOptions = this.excludedOptions[field.id];

    if (excludedOptions) {
      options = options.filter(
        (option) => !excludedOptions.includes(option.id),
      );
    }

    this.addEditListOption(entity, field, options);

    if (
      field.selection === 'single' &&
      field.metadata?.nullable &&
      !field.required &&
      options.findIndex((option) => option.id === null) < 0
    ) {
      const placeholder = this.customPlaceholders[field.id];
      if (placeholder) {
        options.unshift({
          id: null,
          name: this.resource.transform(
            placeholder.resource,
            placeholder.translation,
          ),
        });
      } else {
        options.unshift({ id: null, name: '' });
      }
    }

    return options;
  }

  private addEditListOption(
    entity: string,
    field: SelectorField<unknown>,
    options: ListItem<unknown>[],
  ): void {
    const editableList = this.editableList[entity];
    if (editableList && this.currentUser?.rights.modifySystemTables) {
      const code = field.parentId
        ? (editableList[field.parentId] as Entity)?.[field.id]
        : editableList[field.id];

      if (code) {
        const value = field.parentId
          ? { id: field.id, parentId: field.parentId, code, entity }
          : { id: field.id, code, entity };
        const id = `edit|${JSON.stringify(value)}`;
        if (options.findIndex((option) => option.id === id) < 0) {
          options.unshift({
            id,
            name: this.resource.transform(
              'JSS_GenEditListItemText',
              'texts.editList',
            ),
          });
        }
      }
    }
  }

  private getData(entity: string, field: Field): Entity | undefined {
    const entityData = this.withData[entity];
    const fieldData = entityData ? entityData[field.id] : undefined;
    if (entityData && fieldData) {
      return typeof fieldData === 'function' ? fieldData() : fieldData;
    }

    return undefined;
  }

  private handleSalesTeamChanges(control: LayoutFormControl): void {
    const leader = this.form.control.get(Octopus.OpportunityFields.Leader);
    if (leader instanceof LayoutFormControl) {
      this.setTeamMembers(leader, control.value);

      this.subscriptions.push(
        control.valueChanges
          .pipe(distinctUntilChanged())
          .subscribe((value: string) => this.setTeamMembers(leader, value)),
      );
    }
  }

  private setTeamMembers(leader: LayoutFormControl, value?: string): void {
    if (value) {
      if (value === Octopus.SingleUserKey && this.currentUser) {
        leader.setOptions([
          { id: this.currentUser.key, name: this.currentUser.name },
        ]);
        leader.setValue(this.currentUser.key);
        leader.disable();
      } else {
        leader.enable();

        this.subscriptions.push(
          this.salesTeam.get(value).subscribe((salesTeam) => {
            const members = salesTeam?.members ?? [];
            const inMembers =
              members.filter((member) => member.id === leader.value).length > 0;

            leader.setOptions(members);

            if (!inMembers) {
              const teamLeader = members.find(
                (member) => member.role === 'leader',
              );
              leader.setValue(teamLeader?.id ?? null);
            }
          }),
        );
      }
    }
  }
}
