import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { defaultTranslateConfiguration } from '@maximizer/core/shared/ui';
import { TranslateModule } from '@ngx-translate/core';
import { OutlookFormErrorComponent } from './form-error/form-error.component';
import { OutlookNotificationComponent } from './notification/notification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { OutlookLineSkeletonComponent } from './skeleton/skeleton.component';
import { OverflowTooltipDirective } from './directives';

@NgModule({
  declarations: [
    OutlookFormErrorComponent,
    OutlookNotificationComponent,
    OutlookLineSkeletonComponent,
    OverflowTooltipDirective,
  ],
  exports: [
    OutlookFormErrorComponent,
    OutlookNotificationComponent,
    OutlookLineSkeletonComponent,
    OverflowTooltipDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    InputsModule,
    LabelModule,
    TranslateModule.forChild({
      ...defaultTranslateConfiguration,
      extend: true,
    }),
  ],
})
export class OutlookSharedUiModule {}
