import { Component, Input, ViewChild } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { LocaleService } from '@maximizer/core/shared/data-access';
import { DateTimeField } from '@maximizer/core/shared/domain';
import {
  DatePickerComponent,
  DateTimePickerComponent,
  PopupSettings,
  TimePickerComponent,
} from '@progress/kendo-angular-dateinputs';

@Component({
  selector: 'maximizer-date-field',
  template: `
    <ng-container [ngSwitch]="field.selection" [formGroup]="form.control">
      <kendo-formfield
        *ngSwitchCase="'date'"
        [ngStyle]="field.containerStyle"
        [ngClass]="[field.labelPosition ?? 'left', field.containerClass ?? '']"
      >
        <kendo-label
          maximizerFieldLabel
          [field]="field"
          [for]="input"
          [required]="field.required ?? false"
        >
        </kendo-label>
        <kendo-datepicker
          #input
          calendarType="classic"
          [popupSettings]="popupSettings"
          [readonly]="field.readonly"
          [format]="{
            displayFormat:
              field.displayFormat ??
              locale.data.calendar?.dateFormats?.long ??
              'D',
            inputFormat: field.inputFormat ?? 'd',
          }"
          [placeholder]="field.placeholder ?? ''"
          [min]="field.min!"
          [max]="field.max!"
          [rangeValidation]="true"
          [formControlName]="field.id"
          [ngClass]="field.cssClass"
        ></kendo-datepicker>
        <kendo-formhint *ngIf="field.hint">{{ field.hint }}</kendo-formhint>
        <kendo-formerror *ngLet="form.control.get(field.id) as control">
          <span *ngIf="control?.hasError('required')">
            {{
              'JSS_GenTxtPleaseSelect'
                | resourceOrTranslate: 'error.selectField'
            }}
            {{ field.name }}
          </span>
          <span
            *ngIf="control?.hasError('minError') && field.errorMessages?.min"
            >{{ field.errorMessages?.min }}</span
          >
          <span
            *ngIf="control?.hasError('maxError') && field.errorMessages?.max"
            >{{ field.errorMessages?.min }}</span
          >
        </kendo-formerror>
      </kendo-formfield>

      <kendo-formfield
        *ngSwitchCase="'time'"
        [ngStyle]="field.containerStyle"
        [ngClass]="[field.labelPosition ?? 'left', field.containerClass ?? '']"
      >
        <kendo-label
          maximizerFieldLabel
          [field]="field"
          [for]="input"
          [required]="field.required ?? false"
        >
        </kendo-label>
        <kendo-timepicker
          #input
          [popupSettings]="popupSettings"
          [readonly]="field.readonly"
          [format]="
            field.displayFormat ??
            locale.data.calendar?.timeFormats?.short ??
            't'
          "
          [placeholder]="field.placeholder ?? ''"
          [min]="field.min!"
          [max]="field.max!"
          [rangeValidation]="true"
          [formControlName]="field.id"
          [ngClass]="field.cssClass"
        ></kendo-timepicker>
        <kendo-formhint *ngIf="field.hint">{{ field.hint }}</kendo-formhint>
        <kendo-formerror *ngLet="form.control.get(field.id) as control">
          <span *ngIf="control?.hasError('required')">
            {{
              'JSS_GenTxtPleaseSelect'
                | resourceOrTranslate: 'error.selectField'
            }}
            {{ field.name }}
          </span>
          <span
            *ngIf="control?.hasError('minError') && field.errorMessages?.min"
            >{{ field.errorMessages?.min }}</span
          >
          <span
            *ngIf="control?.hasError('maxError') && field.errorMessages?.max"
            >{{ field.errorMessages?.min }}</span
          >
        </kendo-formerror>
      </kendo-formfield>

      <kendo-formfield
        *ngSwitchDefault
        [ngStyle]="field.containerStyle"
        [ngClass]="[field.labelPosition ?? 'left', field.containerClass ?? '']"
      >
        <kendo-label
          maximizerFieldLabel
          [field]="field"
          [for]="input"
          [required]="field.required ?? false"
        >
        </kendo-label>
        <kendo-datetimepicker
          #input
          calendarType="classic"
          [popupSettings]="popupSettings"
          [readonly]="field.readonly"
          [format]="{
            displayFormat: field.displayFormat ?? 'g',
            inputFormat: field.inputFormat ?? 'g',
          }"
          [placeholder]="field.placeholder ?? ''"
          [min]="field.min!"
          [max]="field.max!"
          [rangeValidation]="true"
          [formControlName]="field.id"
          [ngClass]="field.cssClass"
        ></kendo-datetimepicker>
        <kendo-formhint *ngIf="field.hint">{{ field.hint }}</kendo-formhint>
        <kendo-formerror *ngLet="form.control.get(field.id) as control">
          <span *ngIf="control?.hasError('required')">
            {{
              'JSS_GenTxtPleaseSelect'
                | resourceOrTranslate: 'error.selectField'
            }}
            {{ field.name }}
          </span>
          <span
            *ngIf="control?.hasError('minError') && field.errorMessages?.min"
            >{{ field.errorMessages?.min }}</span
          >
          <span
            *ngIf="control?.hasError('maxError') && field.errorMessages?.max"
            >{{ field.errorMessages?.min }}</span
          >
        </kendo-formerror>
      </kendo-formfield>
    </ng-container>
  `,
  styles: [
    `
      :host {
        @apply w-full;
      }
    `,
  ],
})
export class DateFieldComponent {
  @Input({ required: true })
  field!: DateTimeField;

  @ViewChild('input')
  input!: DateTimePickerComponent | DatePickerComponent | TimePickerComponent;

  readonly popupSettings: PopupSettings = { appendTo: 'component' };

  constructor(
    public locale: LocaleService,
    public form: FormGroupDirective,
  ) {}
}
