<div class="flex p-4 bg-max-white justify-between">
  <div>
    <div
      class="h-8 w-8 bg-neutral-20 rounded-md flex items-center justify-center"
    >
      <span class="svg-icons icon-opportunity-in-progress icon-16 mt-1"></span>
    </div>
  </div>
  <div class="max-w-[calc(100%-4.375rem)] flex-grow px-2 flex flex-col gap-y-1">
    <p class="text-body line-clamp-1">{{ opportunity.objective }}</p>
    <p class="caption line-clamp-1">
      {{ opportunity.abEntry.companyName }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.opportunity.stage' | translate }}:
      {{ opportunity.currentStage }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.opportunity.contact' | translate }}:
      {{ opportunity.contact?.firstName }} {{ opportunity.contact?.lastName }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ 'outlook.opportunity.closeDate' | translate }}:
      {{ opportunity.closeDate }}
    </p>
    <p class="caption text-neutral-70 line-clamp-1">
      {{ opportunity.forecastRevenue?.DisplayValue }}
    </p>
    @if (opportunity.owner) {
      <div>
        <p class="text-body py-1">
          {{ 'outlook.opportunity.owner' | translate }}:
        </p>
        <div class="flex items-center">
          <span class="avatar avatar-xs">{{
            opportunity.owner.display | nameInitials
          }}</span>
          <p class="text-body pl-1">{{ opportunity.owner.display }}</p>
        </div>
      </div>
    }
  </div>
  <span
    kendoTooltip
    class="h-fit"
    [callout]="false"
    [tooltipTemplate]="tooltipTemplate"
    [title]="
      userRights?.opportunities?.update
        ? userRights?.documents?.insert
          ? ('outlook.main.related.saveEmail'
            | translate: { entry: 'outlook.entries.opportunity' | translate })
          : ('outlook.rights.defaultMessage'
            | translate
              : {
                  right: 'outlook.rights.create' | translate,
                  entry: 'outlook.entries.emailLogs' | translate | lowercase,
                })
        : ('outlook.rights.defaultMessage'
          | translate
            : {
                right: 'outlook.rights.edit' | translate,
                entry: 'outlook.entries.opportunities' | translate,
              })
    "
  >
    <button
      kendoButton
      id="outlook-opportunity-ui-opportunityCard-saveEmail-{{
        opportunity.key
      }}"
      fillMode="flat"
      [disabled]="
        opportunity.isSaved ||
        !userRights?.opportunities?.update ||
        !userRights?.documents?.insert
      "
      (click)="save.emit(opportunity.key)"
      [iconClass]="
        opportunity.isSaved
          ? 'svg-icons icon-saved-email text-neutral-40 icon-20'
          : !userRights?.opportunities?.update || !userRights?.documents?.insert
            ? 'maximizer-icons icon-save-email text-neutral-40 icon-24'
            : 'maximizer-icons icon-save-email text-inserv-100 icon-24'
      "
    ></button>
  </span>
</div>

<ng-template #tooltipTemplate let-anchor>
  <p class="caption text-center text-neutral-90 whitespace-nowrap">
    {{ anchor.nativeElement.getAttribute('data-title') }}
  </p>
</ng-template>
