import { withDevtools } from '@angular-architects/ngrx-toolkit';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import {
  Onboarding,
  OnboardingStepNumber,
  StepGuide,
  StepGuideId,
} from '../models';

type OnboardingState = {
  isOnboardingVisible: boolean;
  inProgress: boolean;
  currentStep: OnboardingStepNumber;
  currentGuide: StepGuideId;
  completed: boolean;
  onboardingConfig: Onboarding[];
  configurationId: string;
};

const ONBOARDING_STORAGE_KEY = 'onboardingState';

const OnboardingConfig: Onboarding[] = [
  {
    step: {
      step: OnboardingStepNumber.Step1,
      name: 'outlook.onboarding.addContact',
      status: 'active',
      icon: {
        name: 'building-user',
        type: 'fa',
      },
    },
    guides: [
      {
        stepId: StepGuideId.AddContact1,
        guide: {
          step: 1,
          total: 2,
        },
        title: 'outlook.onboarding.step.addContact.title',
        content: 'outlook.onboarding.step.addContact.step1',
        canNavigate: false,
      },
      {
        stepId: StepGuideId.AddContact1Alt,
        guide: {
          step: 1,
          total: 1,
        },
        title: 'outlook.onboarding.step.addContact.title',
        content: 'outlook.onboarding.step.addContact.step1Alt',
        canNavigate: true,
        button: 'outlook.buttons.continue',
        hideStepCount: true,
      },
      {
        stepId: StepGuideId.AddContact2,
        guide: {
          step: 2,
          total: 2,
        },
        title: 'outlook.onboarding.step.addContact.title',
        content: 'outlook.onboarding.step.addContact.step2',
        canNavigate: false,
      },
    ],
  },
  {
    step: {
      step: OnboardingStepNumber.Step2,
      name: 'outlook.onboarding.saveEmail',
      status: 'default',
      icon: {
        name: 'save-email',
        type: 'maximizer',
      },
    },
    guides: [
      {
        stepId: StepGuideId.SaveEmail1,
        guide: {
          step: 1,
          total: 2,
        },
        title: 'outlook.onboarding.step.saveEmail.title',
        content: 'outlook.onboarding.step.saveEmail.step1',
        canNavigate: false,
      },
      {
        stepId: StepGuideId.SaveEmail2,
        guide: {
          step: 2,
          total: 2,
        },
        title: 'outlook.onboarding.step.saveEmail.title',
        content: 'outlook.onboarding.step.saveEmail.step2',
        canNavigate: true,
        button: 'outlook.buttons.next',
      },
    ],
  },
  {
    step: {
      step: OnboardingStepNumber.Step3,
      name: 'outlook.onboarding.createNote',
      status: 'default',
      icon: {
        name: 'add-note',
        type: 'maximizer',
      },
    },
    guides: [
      {
        stepId: StepGuideId.CreateNote1,
        guide: {
          step: 1,
          total: 3,
        },
        title: 'outlook.onboarding.step.createNote.title',
        content: 'outlook.onboarding.step.createNote.step1',
        canNavigate: false,
      },
      {
        stepId: StepGuideId.CreateNote2,
        guide: {
          step: 2,
          total: 3,
        },

        title: 'outlook.onboarding.step.createNote.title',
        content: 'outlook.onboarding.step.createNote.step2',
        canNavigate: false,
      },
      {
        stepId: StepGuideId.CreateNote3,
        guide: {
          step: 3,
          total: 3,
        },
        title: 'outlook.onboarding.step.createNote.title',
        content: 'outlook.onboarding.step.createNote.step3',
        canNavigate: false,
      },
    ],
  },
  {
    step: {
      step: OnboardingStepNumber.Step4,
      name: 'outlook.onboarding.inviteTeam',
      status: 'default',
      icon: {
        name: 'check',
        type: 'fa',
      },
    },
    guides: [],
  },
];

const initialState: OnboardingState = {
  isOnboardingVisible: false,
  inProgress: false,
  currentStep: OnboardingStepNumber.Step1,
  currentGuide: StepGuideId.AddContact1,
  completed: false,
  onboardingConfig: OnboardingConfig,
  configurationId: '',
};

function saveToStorage(state: OnboardingState): void {
  const stateToSave = { ...state, onboardingConfig: undefined };
  sessionStorage.setItem(ONBOARDING_STORAGE_KEY, JSON.stringify(stateToSave));
}

function loadFromStorage(): OnboardingState {
  const storedState = sessionStorage.getItem(ONBOARDING_STORAGE_KEY);
  const parsedState = storedState ? JSON.parse(storedState) : initialState;
  return { ...parsedState, onboardingConfig: OnboardingConfig };
}

export const OnboardingStore = signalStore(
  withDevtools('onboarding'),
  withState(loadFromStorage()),
  withMethods((store) => ({
    setOnboardingVisibility(isVisible: boolean) {
      patchState(store, (state) => {
        const newState = { ...state, isOnboardingVisible: isVisible };
        saveToStorage(newState);
        return newState;
      });
    },
    setOnboardingInProgress(inProgress: boolean) {
      patchState(store, (state) => {
        const newState = { ...state, inProgress };
        saveToStorage(newState);
        return newState;
      });
    },
    setCurrentStep(currentStep: OnboardingStepNumber) {
      patchState(store, (state) => {
        const newState = { ...state, currentStep };
        saveToStorage(newState);
        return newState;
      });
    },
    setCurrentGuide(currentGuide: StepGuideId) {
      patchState(store, (state) => {
        const newState = { ...state, currentGuide };
        saveToStorage(newState);
        return newState;
      });
    },
    hasCompleted(completed: boolean) {
      patchState(store, (state) => {
        const newState = { ...state, completed };
        saveToStorage(newState);
        return newState;
      });
    },
    setConfigurationId(configurationId: string) {
      patchState(store, (state) => {
        const newState = { ...state, configurationId };
        saveToStorage(newState);
        return newState;
      });
    },
    getGuideForStep(stepId: StepGuideId): StepGuide {
      const defaultGuide: StepGuide = {
        stepId: StepGuideId.AddContact1,
        guide: {
          step: OnboardingStepNumber.Step1,
          total: 1,
        },
        canNavigate: false,
        title: '',
        content: '',
        hideStepCount: false,
      };

      const onboardingConfig = store.onboardingConfig();

      for (const onboarding of onboardingConfig) {
        const guide = onboarding.guides.find(
          (guide) => guide.stepId === stepId,
        );
        if (guide) {
          return guide;
        }
      }
      return defaultGuide;
    },
    updateCanNavigate(stepId: StepGuideId, canNavigate: boolean): void {
      patchState(store, (state) => {
        const updatedOnboardingConfig = state.onboardingConfig.map(
          (onboarding) => {
            return {
              ...onboarding,
              guides: this.updateGuides(onboarding.guides, stepId, canNavigate),
            };
          },
        );

        const newState = {
          ...state,
          onboardingConfig: updatedOnboardingConfig,
        };

        saveToStorage(newState);
        return newState;
      });
    },
    updateGuides(
      guides: StepGuide[],
      stepId: StepGuideId,
      canNavigate: boolean,
    ): StepGuide[] {
      return guides.map((guide) => {
        return guide.stepId === stepId ? { ...guide, canNavigate } : guide;
      });
    },
  })),
);

export type OnboardingStore = InstanceType<typeof OnboardingStore>;
