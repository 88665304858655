export enum GoogleAddressFields {
  StreetNumber = 'street_number',
  Route = 'route',
  Locality = 'locality',
  Sublocality = 'sublocality',
  PostalTown = 'postal_town',
  Political = 'political',
  AdministrativeAreaLevel1 = 'administrative_area_level_1',
  Country = 'country',
  PostalCode = 'postal_code',
}

export const GoogleAddressTypes: Record<string, KnownKeys> = {
  [GoogleAddressFields.StreetNumber]: 'short_name',
  [GoogleAddressFields.Route]: 'short_name',
  [GoogleAddressFields.Locality]: 'long_name',
  [GoogleAddressFields.Sublocality]: 'long_name',
  [GoogleAddressFields.PostalTown]: 'long_name',
  [GoogleAddressFields.Political]: 'long_name',
  [GoogleAddressFields.AdministrativeAreaLevel1]: 'short_name',
  [GoogleAddressFields.Country]: 'long_name',
  [GoogleAddressFields.PostalCode]: 'short_name',
};

export interface GoogleAddress {
  [key: string]: any;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  StateProvince: string;
  Country: string;
  ZipCode: string;
  Latitude: number | null;
  Longitude: number | null;
}
interface GeocoderAddressComponent {
  short_name?: string;
  long_name?: string;
}

type KnownKeys = keyof GeocoderAddressComponent;
