import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  SecurityContext,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContextService,
  FeatureService,
  GlobalStore,
  OAuth2Service,
  SessionService,
  SessionStorageService,
} from '@maximizer/core/shared/data-access';
import {
  OnboardingStore,
  APP_STORE_CONFIGURATION_URL,
  getDecodedAccessToken,
  OutlookSyncService,
  constructAppStoreParams,
  constructAppStoreForm,
  OutlookStore,
  InitOnboardingAction,
} from '@maximizer/outlook/shared/data-access';
import { TranslateService } from '@ngx-translate/core';
import {
  MenuItem,
  OutlookNotificationComponent,
  slideDownAnimation,
} from '@maximizer/outlook/shared/ui';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { InsightsService } from '@maximizer/core/shared/insights';
import { GlobalServicesLoginDetails } from '@maximizer/core/shared/domain';
import { CelebrationComponent } from '@maximizer/outlook/onboarding/ui';

@Component({
  selector: 'maximizer-outlook-menu',
  templateUrl: './menu.component.html',
  animations: [slideDownAnimation],
})
export class OutlookMenuComponent implements OnInit {
  @HostBinding('class.truncated') isTruncated = false;

  @ViewChild('notification')
  notification!: OutlookNotificationComponent;
  @ViewChild('celebration')
  celebration!: CelebrationComponent;

  @Input() pageTitle = '';
  @Input() pageType!: 'lead' | 'abentry' | 'none';
  @Input() id = '';
  @Input() showOnlyTitle = false;
  @Input() hideBack = false;
  @Input() hideRefresh = false;
  @Input() hideLogout = false;
  @Input() backRoutePath = '';
  @Input() forceWindowRefresh = false;
  @Output() exit = new EventEmitter<void>();
  @Output() initFirstOnboardingStep = new EventEmitter<InitOnboardingAction>();

  readonly userGuideUrl =
    'https://support.maximizer.com/hc/en-us/articles/29776800842253-Microsoft-Outlook-Integration-Getting-Started-Guide-Maximizer-AI-CRM-app-for-Outlook';

  menu: MenuItem[];
  deepLink = '';
  logInDetails?: GlobalServicesLoginDetails | null;
  outlookSyncInstalled = false;
  outlookSyncFeatureFlagEnabled = false;
  remindLater = false;
  startOnboarding = false;
  supportedOnboardingPage = true;

  constructor(
    @Inject(APP_STORE_CONFIGURATION_URL) private readonly appStoreUrl: string,
    private readonly contextService: ContextService,
    private readonly session: SessionService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly globalStore: GlobalStore,
    private readonly oAuthService: OAuth2Service,
    private readonly sessionStorage: SessionStorageService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly domSanitizer: DomSanitizer,
    private readonly insightsService: InsightsService,
    private readonly outlookSyncService: OutlookSyncService,
    public onboardingStore: OnboardingStore,
    public feature: FeatureService,
    public outlookStore: OutlookStore,
  ) {
    const showOnboarding = this.feature.isFeatureOn(
      'integration-o365-plg-onboarding-shown',
      false,
    );
    this.onboardingStore.setOnboardingVisibility(showOnboarding);

    this.outlookSyncFeatureFlagEnabled = feature.isFeatureOn(
      'microsoft-outlook-sync-emails-shown',
      false,
    );

    this.menu = [
      {
        id: 'refresh',
        text: this.translate.instant('outlook.menu.refresh'),
        icon: 'fa-icons icon-solid icon-14 icon-refresh',
        click: () => this.refreshWindow(),
      },
      {
        id: 'maximizer',
        text: this.translate.instant('outlook.menu.maximizer'),
        icon: 'fa-icons icon-solid icon-14 icon-arrow-up-right-from-square',
        click: () => {
          if (!this.deepLink) this.deepLink = 'https://www.maximizer.com';
          window.open(this.deepLink, '_blank', 'noopener');
        },
      },
      {
        id: 'settings',
        text: this.translate.instant('outlook.menu.settings'),
        icon: 'fa-icons icon-solid icon-14 icon-arrow-up-right-from-square',
        click: () => this.buildAppStoreFormRequest(),
      },
      {
        id: 'guide',
        text: this.translate.instant('outlook.menu.guide'),
        icon: 'fa-icons icon-14 icon-book',
        click: () => {
          window.open(this.userGuideUrl, '_blank', 'noopener');
        },
      },
      {
        id: 'logout',
        text: this.translate.instant('outlook.menu.logout'),
        icon: 'fa-icons icon-solid icon-14 icon-right-from-bracket',
        click: () => {
          this.logout();
        },
      },
      {
        id: 'appVersion',
        text: `${this.translate.instant('outlook.menu.appVersion')} ${this.contextService.version}`,
        class: 'app-version',
        disabled: true,
      },
      {
        id: 'database',
        text: `${this.translate.instant('outlook.menu.database')} ${this.contextService.alias}`,
        class: 'database',
        disabled: true,
      },
    ];
  }

  ngOnInit(): void {
    this.logInDetails = this.oAuthService.getStorageLoginDetails();
    this.getOutlookSyncSettings();

    const currentPath = this.getCurrentPath();
    if (currentPath === 'login' || currentPath === 'compose-email') {
      this.supportedOnboardingPage = false;
    }

    if (this.hideRefresh) this.menu = this.menu.slice(1);
    if (this.hideLogout) this.menu.pop();
    if (this.showOnlyTitle) {
      this.hideBack = true;
      this.menu = [];
    }

    if (this.contextService.token) {
      const session = this.globalStore.session();
      if (session?.alias) {
        this.populateDeeplink(session.alias);
      } else {
        this.session.getInfo().subscribe((data) => {
          this.populateDeeplink(data.alias);
        });
      }
    }
  }

  private getCurrentPath(): string | undefined {
    let route = this.route.snapshot;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.routeConfig?.path;
  }

  getOutlookSyncSettings(): void {
    const session = this.globalStore.session();

    if (
      session &&
      this.outlookSyncFeatureFlagEnabled &&
      this.outlookStore.versionValidForNewSync()
    ) {
      this.outlookSyncService
        .getConfiguration(session.user.id, session.workspace)
        .subscribe((config) => {
          this.outlookSyncInstalled = !!config?.enabled;
          this.updateMenu();
        });
    } else {
      this.outlookSyncInstalled = false;
      this.updateMenu();
    }
  }

  private updateMenu(): void {
    if (!this.outlookSyncInstalled) {
      this.menu = [...this.menu.filter((item) => item.id !== 'settings')];
    }
  }

  private populateDeeplink(alias: string): void {
    const urlArray = this.contextService.website.split('/');
    const domainIndex = urlArray.findIndex((o) =>
      o.toLocaleLowerCase().includes('maximizer'),
    );
    if (domainIndex == -1) return;
    let domain = '';
    for (let i = 0; i <= domainIndex; i++) {
      domain += urlArray[i] + '/';
    }
    const accountLink = domain?.replace('1', 'w') + alias;
    switch (this.pageType) {
      case 'lead':
        this.deepLink = `${accountLink}?sslead=KEY(${this.id})`;
        break;
      case 'abentry':
        this.deepLink = `${accountLink}?ss=KEY(${this.id})`;
        break;
      default:
        this.deepLink = accountLink;
        break;
    }
  }

  private isRouteValid(path: string): boolean {
    const configuredPaths = this.router.config.map((route) => `/${route.path}`);
    return configuredPaths.includes(path);
  }

  goBack(): void {
    if (this.backRoutePath) {
      if (this.isRouteValid(this.backRoutePath)) {
        this.router.navigate([this.backRoutePath]);
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  logout(): void {
    this.oAuthService.clearAuth();
    this.session.clearSessionCache();
    this.contextService.clearAuthentication();
    this.sessionStorage.clear();

    const loginPath = '/login';
    const destinationUrl = window.location.origin + loginPath;

    if (destinationUrl.startsWith(window.location.origin)) {
      window.location.href = destinationUrl;
    }
  }

  private refreshWindow(): void {
    if (this.forceWindowRefresh) {
      const destinationUrl = `${window.location.origin}${window.location.pathname}`;
      window.location.href = destinationUrl;
      return;
    }

    const path = this.router.url.split('?')[0];
    const queryParamStr = this.router.url.split('?')[1];
    const queryParams = queryParamStr ? queryParamStr.split('&') : [];

    const url = self
      ? decodeURIComponent(this.router.url)
      : decodeURIComponent(this.location.path());
    const refreshUrl = url === '/' ? '../' : '/';

    this.router
      .navigateByUrl(refreshUrl, { skipLocationChange: false })
      .then(() => {
        const pathDecomposed = decodeURIComponent(path);
        const sanitizedQueryParams = this.sanitizeQueryParams(queryParams);
        const record = Object.fromEntries(
          sanitizedQueryParams.map((item) => {
            const [key, value] = item.split('=');
            return [key, decodeURIComponent(value)];
          }),
        ) as Record<string, string>;
        this.router.navigate([pathDecomposed], { queryParams: record });
      });
  }

  private sanitizeQueryParams(queryParams: string[]): string[] {
    return queryParams.map((param) => {
      const [key, value] = param.split('=');
      const sanitizedKey =
        this.domSanitizer.sanitize(SecurityContext.URL, key) || '';
      const sanitizedValue =
        this.domSanitizer.sanitize(SecurityContext.URL, value) || '';
      return `${sanitizedKey}=${sanitizedValue}`;
    });
  }

  buildAppStoreFormRequest(): void {
    const { alias, token, tenantId } = this.contextService;
    const session = this.globalStore.session();

    if (!session || !tenantId || !this.logInDetails) {
      return;
    }

    const urlParams = constructAppStoreParams(
      session,
      alias,
      token,
      tenantId,
      this.logInDetails,
      false,
      this.insightsService,
    );

    if (!urlParams) {
      this.handleAppStoreFromError(
        'Menu-AppStore-Params',
        'Missing app store form parameters',
      );
      return;
    }

    const formDetails = constructAppStoreForm(
      token,
      urlParams,
      this.appStoreUrl,
      this.insightsService,
    );

    if (!formDetails) {
      this.handleAppStoreFromError(
        'Menu-AppStore-Missing-Form-Details-Error',
        'App store form details incomplete.',
      );
      return;
    }

    this.handleAppStoreFormSubmission(formDetails.form, formDetails.window);
  }

  handleAppStoreFormSubmission(
    appStoreForm: HTMLFormElement,
    newWindow: Window,
  ): void {
    try {
      newWindow.document.body.appendChild(appStoreForm);
      appStoreForm.submit();
      newWindow.focus();
    } catch (error) {
      const typedError = error as Error;
      this.handleAppStoreFromError(
        'Menu-AppStore-Form-Submit-Error',
        typedError.message,
      );
    } finally {
      this.logDetailsToInsights('Menu-AppStore-Form-Success', 'n/a');
      newWindow.document.body.removeChild(appStoreForm);
    }
  }

  handleAppStoreFromError(name: string, error: string): void {
    this.notification.showMessage('error', 'outlook.errors.appStore', true);
    this.logDetailsToInsights(name, error);
    console.error(`An error occurred opening the App Store page: ${error}`);
  }

  logDetailsToInsights(name: string, error: string): void {
    const customProperties = {
      error,
      decodedToken: getDecodedAccessToken(this.contextService.token),
      databaseAlias: this.globalStore.session()?.alias,
      eventId: 'app-store-request',
    };

    this.insightsService.trackEvent(
      { name },
      SeverityLevel.Information,
      customProperties,
    );
  }

  // Onboarding
  handleOnboardingAction(action: InitOnboardingAction): void {
    this.startOnboarding = false;

    if (action === 'start') {
      this.onboardingStore.setOnboardingInProgress(true);

      const currentPath = this.getCurrentPath();

      if (currentPath !== 'read-email') {
        this.router.navigate(['/home']);
      } else {
        this.initFirstOnboardingStep.emit('start');
      }
    } else {
      this.remindLater = true;
      this.onboardingStore.setOnboardingInProgress(false);
    }
  }
}
